// src/components/Logo.js
import React from 'react';
import { Box } from '@mui/material';

const Logo = () => {
  return (
    <Box
      component="img"
      src="https://storage.googleapis.com/facemyai-media-bucket/brand/logo-fondotransparente-lineasnegras.png"
      alt="FaceMyAI Logo"
      sx={{ height: { xs: 0, sm: 60 }, width: 'auto' }}
    />
  );
};

export default Logo;
