// src/components/PrivacyPolicy.js

import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        PRIVACY POLICY IN ACCORDANCE WITH ARTICLE 13 OF REGULATION (EU) NO. 2016/679
      </Typography>
      <Typography variant="body1" paragraph>
        At FaceMyAI, S.L., we are committed to protecting your personal data and respecting your privacy. This Privacy Policy outlines the types of personal information we collect, how we use it, and the measures we take to ensure it is handled securely and in compliance with applicable laws and regulations.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">DATA CONTROLLER</Typography>
        <Typography variant="body1" paragraph>
          • Entity Responsible: FaceMyAI, S.L.<br />
          • Address: calle maria moliner numero 69, escalera izquierda 8º Derecha, Zaragoza, España<br />
          • Telephone: +34 655359830<br />
          • Email: <Link href="mailto:facemyai@facemyai.com">facemyai@facemyai.com</Link>
        </Typography>
        <Typography variant="body1" paragraph>
          You may contact us regarding any questions or concerns about personal data protection at the above email address.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">CATEGORIES OF PERSONAL DATA SUBJECT TO PROCESSING</Typography>
        <Typography variant="body1" paragraph>
          We may collect and process the following categories of personal data:
        </Typography>
        <ul>
          <li><strong>Identification Data:</strong> Name, surname, email address, postal address, telephone number, country of residence.</li>
          <li><strong>Professional Data:</strong> Curriculum vitae, employment history (if you apply for a job with us).</li>
          <li><strong>Usage Data:</strong> IP address, connection data, browsing data (if authorized by the user), interaction with our services.</li>
          <li><strong>Communication Data:</strong> Any correspondence or communications you send to us.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">PURPOSES OF PROCESSING PERSONAL DATA</Typography>
        <Typography variant="body1" paragraph>
          Your personal data may be processed for the following purposes:
        </Typography>
        <ol>
          <li><strong>Provision of Services:</strong> To provide the services described on our website, including user account management, and to respond to your inquiries or requests.</li>
          <li><strong>Contractual Obligations:</strong> To manage and fulfill our contractual or pre-contractual obligations with users and customers.</li>
          <li><strong>Recruitment Processes:</strong> To process job applications, evaluate candidates, and keep applicants informed about future vacancies.</li>
          <li><strong>Marketing and Advertising:</strong> With your consent, to send you information about products, services, or offers that may be of interest to you.</li>
          <li><strong>Legal Compliance:</strong> To comply with legal obligations, regulations, or governmental requests.</li>
        </ol>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">LEGAL BASES FOR PROCESSING</Typography>
        <Typography variant="body1" paragraph>
          The legal bases for processing your personal data are:
        </Typography>
        <ul>
          <li><strong>Consent:</strong> You have given explicit consent for one or more specific purposes (e.g., receiving marketing communications).</li>
          <li><strong>Contract Performance:</strong> Processing is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into a contract.</li>
          <li><strong>Legal Obligation:</strong> Processing is necessary for compliance with a legal obligation to which we are subject.</li>
          <li><strong>Legitimate Interests:</strong> Processing is necessary for the purposes of our legitimate interests, such as improving our services, provided that such interests are not overridden by your rights and interests.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">RECIPIENTS OF PERSONAL DATA</Typography>
        <Typography variant="body1" paragraph>
          We do not sell or rent your personal data to third parties. However, we may disclose your personal data to:
        </Typography>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors who provide services on our behalf (e.g., cloud hosting, email communication services) and who process your data under strict confidentiality agreements.</li>
          <li><strong>Legal and Regulatory Authorities:</strong> When disclosure is required by law or necessary to protect our rights or comply with judicial proceedings.</li>
          <li><strong>Professional Advisors:</strong> Such as lawyers or auditors, in order to comply with our legal obligations.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">TRANSFER OF DATA TO THIRD COUNTRIES OR INTERNATIONAL ORGANIZATIONS</Typography>
        <Typography variant="body1" paragraph>
          Your personal data may be transferred to countries outside the European Economic Area (EEA). In such cases, we ensure that appropriate safeguards are in place, such as Standard Contractual Clauses approved by the European Commission, to protect your data.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">RETENTION OF YOUR PERSONAL DATA</Typography>
        <Typography variant="body1" paragraph>
          We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </Typography>
        <ul>
          <li><strong>Service-Related Data:</strong> Retained for the duration of the contractual relationship and, after termination, for the period required by applicable law.</li>
          <li><strong>Marketing Data:</strong> Retained until you withdraw your consent or request deletion.</li>
          <li><strong>Recruitment Data:</strong> Retained for a maximum of two years unless you consent to a longer period.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">YOUR RIGHTS</Typography>
        <Typography variant="body1" paragraph>
          You have the following rights regarding your personal data:
        </Typography>
        <ol>
          <li><strong>Access:</strong> Obtain confirmation as to whether or not we are processing your personal data and access that data.</li>
          <li><strong>Rectification:</strong> Request correction of inaccurate or incomplete data.</li>
          <li><strong>Erasure ("Right to be Forgotten"):</strong> Request the deletion of your personal data when it is no longer necessary or if you withdraw consent.</li>
          <li><strong>Restriction:</strong> Request the restriction of processing under certain circumstances.</li>
          <li><strong>Data Portability:</strong> Receive your personal data in a structured, commonly used, and machine-readable format and have it transmitted to another controller.</li>
          <li><strong>Objection:</strong> Object to the processing of your personal data based on legitimate interests or for direct marketing purposes.</li>
          <li><strong>Withdraw Consent:</strong> At any time, without affecting the lawfulness of processing based on consent before its withdrawal.</li>
        </ol>
        <Typography variant="body1" paragraph>
          To exercise these rights, please contact us at <Link href="mailto:facemyai@facemyai.com">facemyai@facemyai.com</Link> with the subject line "Data Protection Rights" and provide sufficient information for us to verify your identity.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">SPECIAL CATEGORIES OF PERSONAL DATA</Typography>
        <Typography variant="body1" paragraph>
          We do not intentionally collect or process any special categories of personal data (e.g., data revealing racial or ethnic origin, political opinions, religious beliefs, or health information) unless required by law or with your explicit consent.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">AUTOMATED DECISION-MAKING</Typography>
        <Typography variant="body1" paragraph>
          We do not use automated decision-making processes, including profiling, that produce legal effects concerning you or similarly significantly affect you.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">COOKIES AND TRACKING TECHNOLOGIES</Typography>
        <Typography variant="body1" paragraph>
          Our website uses cookies and similar technologies to enhance user experience and analyze website performance. For detailed information, please refer to our <Link href="/cookie-policy">Cookie Policy</Link>.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">SECURITY MEASURES</Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. This includes encryption, access controls, and secure protocols.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">RIGHT TO COMPLAIN</Typography>
        <Typography variant="body1" paragraph>
          If you believe that your data protection rights have been violated, you have the right to lodge a complaint with the relevant supervisory authority, such as the Spanish Data Protection Agency (Agencia Española de Protección de Datos - AEPD).
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">UPDATES TO THIS PRIVACY POLICY</Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review our Privacy Policy periodically.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">CONTACT INFORMATION</Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy or our data processing practices, please contact us at:
        </Typography>
        <ul>
          <li><strong>Email:</strong> <Link href="mailto:facemyai@facemyai.com">facemyai@facemyai.com</Link></li>
          <li><strong>Telephone:</strong> +34 655359830</li>
          <li><strong>Address:</strong> calle maria moliner numero 69, escalera izquierda 8º Derecha, Zaragoza, España</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph>
          ________________________________________
        </Typography>
        <Typography variant="body1" paragraph>
          By using our services, you acknowledge that you have read and understood this Privacy Policy.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
