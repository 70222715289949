// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/Home';
import VerifyEmail from './components/Auth/VerifyEmail';
import Assistant from './components/Assistant/Assistant';
import AdminPanel from './components/Admin/AdminPanel';
import PrivateRoute from './components/PrivateRoute';
import CreateBanner from './components/CreateBanner';
import CreateAvatar from './components/Admin/CrearAsistentes';
import ConfirmEmailPage from './components/ConfirmEmailPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import MyAssistants from './components/Assistant/MisAsistentes';
import Discovery from './components/Assistant/Discovery';
import EditAssistant from './components/Assistant/EditAsistente';
import PrivacyPolicy from './components/PrivacyPolicy'; // Importar el componente PrivacyPolicy
import TermsAndConditions from './components/TermsAndConditions'; // Importar el componente TermsAndConditions

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  // Define tu tema aquí
});

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ToastContainer />
        <Routes>
          {/* Rutas de Autenticación */}
          <Route path="/" element={<Home setIsDarkMode={setIsDarkMode} />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route path="/confirm_email" element={<ConfirmEmailPage />} />

          {/* Rutas Protegidas */}
          <Route
            path="/createbanner"
            element={
              <PrivateRoute>
                <CreateBanner setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/createavatar"
            element={
              <PrivateRoute>
                <CreateAvatar setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/assistant/:assistantId"
            element={
              <PrivateRoute>
                <Assistant isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/editassistant/:assistantId"
            element={
              <PrivateRoute>
                <EditAssistant isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <PrivateRoute>
                <AdminPanel isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/myassistants"
            element={
              <PrivateRoute>
                <MyAssistants isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />
          <Route
            path="/discovery"
            element={
              <PrivateRoute>
                <Discovery isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
              </PrivateRoute>
            }
          />

          {/* Nuevas Rutas para Privacy Policy y Terms and Conditions */}
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />

          {/* Añadir más rutas protegidas si es necesario */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
