// src/components/Assistant/VideoPlayer.js

import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

const VideoPlayer = ({ videoUrl, placeholderImage }) => {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);

  const handleLoadedData = () => {
    setIsVideoLoading(false);
  };

  const handleError = () => {
    setIsVideoLoading(false);
    setVideoError(true);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
      }}
    >
      {isVideoLoading && (
        <Box className="loader" sx={{ alignSelf: 'center', marginTop: '50px' }}></Box>
      )}
      {videoUrl && !videoError && (
        <video
          src={videoUrl}
          autoPlay
          playsInline
          onLoadedData={handleLoadedData}
          onError={handleError}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: isVideoLoading ? 'none' : 'block',
          }}
        >
          Tu navegador no soporta el elemento de video.
        </video>
      )}
      {videoError && (
        <Typography color="error" align="center">
          Error al cargar el video.
        </Typography>
      )}
      {!videoUrl && !isVideoLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <img
            src={placeholderImage}
            alt="Placeholder de Video"
            style={{ width: '100px', height: '100px', marginBottom: '10px' }}
          />
          <Typography variant="body2" color="#666666">
            El asistente está listo para ayudarte.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default VideoPlayer;
