// src/components/Footer.js
import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#f7f7f8', p: 4, mt: 4, marginLeft: { sm: '0', md: '300px' } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear()} FaceMyAI. Todos los derechos reservados.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, gap: 2, flexWrap: 'wrap' }}>
            <Link href="#" variant="body2" color="textSecondary">
              Información
            </Link>
            <Link href="#" variant="body2" color="textSecondary">
              Empleo
            </Link>
            <Link href="#" variant="body2" color="textSecondary">
              Blog
            </Link>
            <Link href="#" variant="body2" color="textSecondary">
              Investigación
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
