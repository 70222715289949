// src/components/Home.js

import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { 
  Box, 
  Container, 
  useMediaQuery, 
  Typography, 
  Button, 
  Grid, 
  useTheme,
  AppBar, 
  Toolbar, 
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Chip,
  Card,
  CardContent,
  Tooltip,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
  Menu as MenuIcon, 
  Add, 
  Close, 
  Refresh as RefreshIcon,
  ContactMail as ContactMailIcon, 
  BugReport as BugReportIcon, 
} from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import Footer from './Footer';
import LoginModal from './LoginModal';
import Sidebar from './Sidebar';
import CookieConsent from './CookieConsent';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './ErrorBoundary';
import './Home.css';

// Contexto de Autenticación para manejar el estado de login en toda la aplicación
const AuthContext = createContext();

// Hook personalizado para acceder al contexto de autenticación
const useAuth = () => {
  return useContext(AuthContext);
};

// Definición del tema de Material-UI para estilizar la aplicación
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    mode: 'light', // Puedes cambiar a 'dark' si es necesario
    primary: {
      main: '#4299e1', // Azul
    },
    secondary: {
      main: '#f50057', // Rojo
    },
    background: {
      default: '#f5f5f5', // Color de fondo
    },
  },
});

// Componente Logo: Muestra el logo de la aplicación
const Logo = React.memo(() => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <img 
      src="https://storage.googleapis.com/facemyai-media-bucket/brand/facemyai-logo-black-letters.jpg" 
      alt="Logo" 
      style={{ height: '40px' }} 
    />
  </Box>
));

// Componente ProtectedButton: Botón que requiere autenticación para su funcionalidad
const ProtectedButton = React.memo(({ children, onClick, component = 'button', ...props }) => {
  const { isAuthenticated, triggerLogin } = useAuth();

  // Maneja el clic del botón
  const handleClick = useCallback((e) => {
    if (!isAuthenticated) {
      e.preventDefault();
      triggerLogin(); // Abre el modal de login si no está autenticado
    } else {
      if (onClick) onClick(e); // Ejecuta la función pasada si está autenticado
    }
  }, [isAuthenticated, onClick, triggerLogin]);

  return (
    <Button 
      {...props} 
      onClick={handleClick} 
      aria-label={typeof children === 'string' ? children : undefined}
      component={component}
    >
      {children}
    </Button>
  );
});

// Componente NavBar: Barra de navegación que se muestra solo cuando el usuario no está autenticado
const NavBar = React.memo(() => {
  const { isAuthenticated, onLogout, triggerLogin } = useAuth();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Función para alternar el estado del Drawer
  const toggleDrawer = useCallback((open) => () => {
    setDrawerOpen(open);
    console.log(`Drawer is now ${open ? 'open' : 'closed'}`);
  }, []);

  // Contenido del Drawer (menú lateral)
  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {!isAuthenticated ? (
          <>
            <ListItem button onClick={triggerLogin} aria-label="Inicia sesión">
              <ListItemText primary="Inicia sesión" />
            </ListItem>
            <ListItem button onClick={triggerLogin} aria-label="Crear cuenta">
              <ListItemText primary="Crear cuenta" />
            </ListItem>
          </>
        ) : (
          <ListItem button onClick={onLogout} aria-label="Cerrar sesión">
            <ListItemText primary="Cerrar sesión" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" color="inherit" elevation={1} sx={{ padding: '0 20px' }}>
      <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logo />
        {isSmallScreen ? (
          <>
            {/* Icono de menú para pantallas pequeñas */}
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {/* Drawer que aparece al hacer clic en el icono de menú */}
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {/* Botón para cerrar el Drawer */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <IconButton onClick={toggleDrawer(false)} aria-label="Cerrar menú">
                  <Close />
                </IconButton>
              </Box>
              {drawerList}
            </Drawer>
          </>
        ) : (
          !isAuthenticated && (
            /* Botones de inicio de sesión y creación de cuenta para pantallas grandes */
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ProtectedButton
                variant="contained"
                color="primary"
                sx={{ borderRadius: '20px', marginLeft: 2 }}
                onClick={triggerLogin}
              >
                Inicia sesión
              </ProtectedButton>
              <ProtectedButton
                variant="outlined"
                color="primary"
                sx={{ borderRadius: '20px', marginLeft: 1 }}
                onClick={triggerLogin} // Puedes cambiar esto a una función para crear cuenta si es necesario
              >
                Crear cuenta
              </ProtectedButton>
            </Box>
          )
        )}
      </Toolbar>
    </AppBar>
  );
});

// Componente ActionButtons: Botones de acción principal en la página de inicio
const ActionButtons = React.memo(() => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Estilos comunes para los botones de acción
  const buttonStyles = {
    borderRadius: '20px',
    color: '#fff',
    minWidth: isSmallScreen ? '150px' : '200px',
    flex: '0 0 auto',
    padding: isSmallScreen ? '8px 16px' : '10px 20px',
    fontSize: isSmallScreen ? '0.875rem' : '1rem',
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        gap: 2, 
        overflowX: 'auto', 
        my: 4, 
        justifyContent: 'center', 
        flexWrap: 'nowrap',
        padding: '0 16px',
      }}
    >
      {/* Botón para reportar errores */}
      <ProtectedButton
        variant="contained"
        startIcon={<BugReportIcon />} // Cambiado a BugReportIcon
        sx={{ 
          ...buttonStyles,
          backgroundColor: '#ff5722', // Color naranja para destacar
        }}
        component="a"
        href="mailto:facemyai@facemyai.com?subject=Bug%20Report"
        aria-label="Reportar Error"
      >
        Reportar Error
      </ProtectedButton>
      {/* Botón para contactar con nosotros */}
      <ProtectedButton
        variant="contained"
        startIcon={<ContactMailIcon />} // Cambiado a ContactMailIcon
        sx={{ 
          ...buttonStyles,
          backgroundColor: '#2196f3',
        }}
        component="a"
        href="mailto:facemyai@facemyai.com"
        aria-label="Contacta con Nosotros"
      >
        Contacta con Nosotros
      </ProtectedButton>
      {/* Puedes añadir más botones según sea necesario */}
    </Box>
  );
});

// Componente FlexBox: Componente reutilizable para Flexbox
const FlexBox = ({ children, direction = 'row', justify = 'flex-start', align = 'flex-start', ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        width: '100%',
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

// Componente CharacterCard: Tarjeta que representa a un asistente
const CharacterCard = React.memo(({ asistente, variant }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Maneja el clic en la tarjeta para navegar al chat del asistente
  const handleCardClick = useCallback(() => {
    navigate(`/assistant/${asistente.AssistantID}`);
    console.log(`Iniciando chat con ${asistente.Name}`);
  }, [asistente.AssistantID, asistente.Name, navigate]);

  // Función para truncar texto si excede cierta longitud
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  // Estilos comunes para todas las variantes de la tarjeta
  const commonCardStyles = {
    borderRadius: '15px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#fff',
    transition: 'background 0.5s ease, transform 0.3s',
    '&:hover .hover-gradient': {
      left: 0,
    },
    '&:hover': {
      transform: variant === 'carousel' ? 'scale(1.05)' : 'none',
    },
  };

  // Estilos específicos según la variante de la tarjeta
  const variantStyles = {
    default: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: { xs: 1, md: 2 },
      height: { xs: '80px', sm: '100px' },
      width: '100%',
    },
    carousel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: { xs: 1, md: 2 },
      minHeight: { xs: '120px', sm: '150px' },
      flex: '0 0 auto',
      width: { xs: '120px', sm: '150px', md: '250px' },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: { xs: 1, md: 2 },
      minHeight: '150px',
      flex: '1 1 100%',
    },
  };

  // Estilos para la imagen del asistente según la variante
  const imageStyles = variant === 'default'
    ? {
        width: { xs: '40px', sm: '60px', md: '80px' },
        height: { xs: '40px', sm: '60px', md: '80px' },
        borderRadius: '50%',
        objectFit: 'cover',
        marginRight: '16px',
      }
    : {
        width: { xs: '80px', sm: '100px' },
        height: { xs: '80px', sm: '100px' },
        borderRadius: '10px',
        objectFit: 'cover',
        margin: { xs: '12px auto 6px', sm: '16px auto 8px' },
      };

  // Estilos para el contenido en variante 'default'
  const defaultContentStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
  };

  // Estilos para el contenido en otras variantes
  const contentStyles = {
    padding: { xs: '6px 12px', md: '16px 24px' },
    textAlign: variant === 'default' ? 'left' : 'center',
  };

  // Extraer y mapear propiedades de estadísticas
  const totalConversations = asistente.TotalConversations || asistente.num_conversations || 0;
  const num_favorites = asistente.num_favorites || asistente.TotalFavorites || 0;
  const num_likes = asistente.num_likes || asistente.TotalLikes || 0;

  return (
    <ProtectedButton 
      onClick={handleCardClick} 
      sx={{ width: '100%', padding: 0, textAlign: 'left' }} 
      component="div" 
      aria-label={`Chatea con ${asistente.Name}`}
    >
      <Card sx={{ 
        ...commonCardStyles, 
        ...variantStyles[variant] 
      }}>
        {/* Imagen del asistente */}
        <Box
          component="img"
          src={asistente.ImageURL || 'https://storage.googleapis.com/facemyai-media-bucket/brand/facemyai-logo-black-letters.jpg'}
          alt={asistente.Name || 'Nombre Desconocido'}
          sx={{
            ...imageStyles,
            maxWidth: '100%', // Asegura que la imagen no exceda el contenedor
            height: 'auto',    // Mantiene la proporción de la imagen
          }}
        />
        {/* Contenido de la tarjeta según la variante */}
        {variant === 'default' ? (
          <Box sx={defaultContentStyles}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 'bold', 
                fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              title={asistente.Name || 'Nombre Desconocido'}
            >
              {truncateText(asistente.Name || 'Nombre Desconocido', 25)}
            </Typography>
            <Typography 
              variant="caption" 
              color="textSecondary" 
              sx={{ 
                fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.875rem' },
              }}
            >
            </Typography>
          </Box>
        ) : (
          <CardContent sx={contentStyles}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 'bold', 
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.25rem' },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              title={asistente.Name || 'Nombre Desconocido'}
            >
              {asistente.Name || 'Nombre Desconocido'}
            </Typography>
            {variant !== 'default' && (
              <>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  sx={{ 
                    marginY: 1, 
                    fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                  title={asistente.Description || 'Descripción no disponible.'}
                >
                  {asistente.Description || 'Descripción no disponible.'}
                </Typography>
                <Typography 
                  variant="caption" 
                  color="textSecondary" 
                  sx={{ 
                    marginBottom: 2, 
                    display: 'block',
                    fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.875rem' },
                  }}
                >
                </Typography>
              </>
            )}
          </CardContent>
        )}
        {/* Elemento para el efecto hover */}
        <Box
          className="hover-gradient"
          sx={{
            position: 'absolute',
            top: 0,
            left: '-100%',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to right, rgba(50, 50, 50, 0.6), rgba(50, 50, 50, 0))',
            pointerEvents: 'none',
            transition: 'all 0.5s ease',
            zIndex: 1,
          }}
        ></Box>
        {/* Conversaciones, Me gusta y Favoritos centrados */}
        <Box 
          sx={{ 
            position: 'absolute', 
            bottom: 8, 
            left: '50%', 
            transform: 'translateX(-50%)', 
            display: 'flex', 
            gap: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: '4px 8px',
            borderRadius: '8px',
          }}
        >
          <Tooltip title="Conversaciones">
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#757575' }}>
              <ChatBubbleOutlineIcon fontSize="small" />
              <Typography variant="caption" sx={{ marginLeft: '4px' }}>
                {totalConversations}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Me gusta">
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#757575' }}>
              <ThumbUpAltOutlinedIcon fontSize="small" />
              <Typography variant="caption" sx={{ marginLeft: '4px' }}>
                {num_likes}
              </Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Favoritos">
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#757575' }}>
              <FavoriteIcon fontSize="small" />
              <Typography variant="caption" sx={{ marginLeft: '4px' }}>
                {num_favorites}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Card>
    </ProtectedButton>
  );
});

// Componente CharacterGrid: Grid que contiene múltiples CharacterCards
const CharacterGrid = React.memo(({ asistentes, variant }) => {
  // Determina el tamaño de los items en el grid según la variante
  const getGridItemSize = useCallback(() => {
    switch (variant) {
      case 'default':
        return { xs: 6, sm: 6, md: 4 };
      case 'header':
        return { xs: 6, sm: 6, md: 6 };
      case 'carousel':
        return { xs: 12, sm: 6, md: 4 };
      default:
        return { xs: 6, sm: 6, md: 4 };
    }
  }, [variant]);

  const gridItemSize = getGridItemSize();

  return (
    <Grid container spacing={2}>
      {asistentes && asistentes.length > 0 ? (
        asistentes.map((asistente) => (
          <Grid item {...gridItemSize} key={asistente.AssistantID || asistente.id}>
            <CharacterCard 
              asistente={asistente} 
              variant={variant} 
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" align="center">
            No hay asistentes disponibles.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});

// Componente CharacterCarousel: Carrusel horizontal de asistentes
const CharacterCarousel = React.memo(({ asistentes, title }) => {
  return (
    <Box sx={{ marginTop: 4, width: '100%' }}>
      <Typography variant="h5" gutterBottom align="center">
        {title}
      </Typography>
      {asistentes && asistentes.length > 0 ? (
        <Box 
          sx={{ 
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            padding: 2,
            // Estilos para personalizar la barra de desplazamiento
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          {asistentes.map((asistente) => (
            <Box 
              key={asistente.AssistantID || asistente.id} 
              sx={{ 
                flex: '0 0 auto',
                width: { xs: '120px', sm: '150px', md: '250px' },
              }}
            >
              <CharacterCard 
                asistente={asistente} 
                variant="carousel" 
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" color="textSecondary" align="center">
          No hay asistentes para mostrar en el carrusel.
        </Typography>
      )}
    </Box>
  );
});

// Componente CharacterSection: Sección que agrupa un título y un grid de asistentes
const CharacterSection = React.memo(({ title, asistentes, variant = 'default' }) => (
  <Box sx={{ mt: 4, padding: '20px', backgroundColor: '#f9f9f9' }}>
    <Typography 
      variant="h5" 
      gutterBottom 
      align="center" 
      sx={{ 
        fontSize: { xs: '1.2rem', md: '1.5rem' }, 
        marginBottom: '20px', 
        color: '#333' 
      }}
    >
      {title}
    </Typography>
    <CharacterGrid asistentes={asistentes} variant={variant} />
  </Box>
));

// Componente CategoryFilters: Filtros por categorías usando Chips
const CategoryFilters = React.memo(({ categories, onFilterChange }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Maneja el clic en un Chip para filtrar las categorías
  const handleChipClick = useCallback((categoryId) => {
    const newFilter = categoryId === activeFilter ? null : categoryId;
    setActiveFilter(newFilter);
    onFilterChange(newFilter);
    console.log(`Filtro de categoría cambiado a: ${newFilter || 'Todas las categorías'}`);
  }, [activeFilter, onFilterChange]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, flexWrap: 'wrap', my: 4 }}>
      {categories && categories.length > 0 ? (
        categories.map((category) => (
          <Chip
            key={category.TypeID}
            label={category.TypeName}
            clickable
            color={activeFilter === category.TypeID ? 'primary' : 'default'}
            onClick={() => handleChipClick(category.TypeID)}
            sx={{ minWidth: isSmallScreen ? '100px' : '150px' }}
            aria-label={`Filtrar por ${category.TypeName}`}
          />
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          No hay categorías disponibles.
        </Typography>
      )}
    </Box>
  );
});
// Componente CreateCharacterSection: Sección para crear un nuevo personaje
const CreateCharacterSection = React.memo(() => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Maneja el clic en el botón para crear un asistente
  const handleCreateAssistant = useCallback(() => {
    navigate('/createavatar');
    console.log('Redirigiendo a /createavatar');
  }, [navigate]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 6,
        backgroundImage: 'url(https://storage.googleapis.com/facemyai-media-bucket/brand/footer-createcharactersection/banner-create-assistant.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        padding: isSmallScreen ? '40px 20px' : '60px 20px',
      }}
    >
      <Typography variant={isSmallScreen ? "h5" : "h4"} gutterBottom>
        ¡Crea tu propio personaje!
      </Typography>
      <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
        <ProtectedButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<Add />}
          sx={{ borderRadius: '20px', mt: 2 }}
          onClick={handleCreateAssistant}
          aria-label="Crear Asistente"
        >
          Crear Asistente
        </ProtectedButton>
      </motion.div>
    </Box>
  );
});

// Componente WelcomeSection: Sección de bienvenida con una imagen de banner y algunos asistentes destacados
const WelcomeSection = React.memo(({ asistentes = [], onRefresh, banners = [] }) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Efecto para rotar los banners cada 7 segundos
  useEffect(() => {
    if (banners.length === 0) return; // No hacer nada si no hay banners
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
      console.log('Cambiando a banner índice:', (currentBannerIndex + 1) % banners.length);
    }, 7000); // Cambiar cada 7 segundos

    return () => clearInterval(interval);
  }, [banners.length, currentBannerIndex]);

  // Maneja el refresco manual del banner
  const handleRefresh = useCallback(() => {
    if (banners.length === 0) return;
    setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    console.log('Banner refrescado a índice:', (currentBannerIndex + 1) % banners.length);
    if (onRefresh) {
      onRefresh();
    }
  }, [banners.length, currentBannerIndex, onRefresh]);

  // Obtener el banner actual
  const currentBanner = banners[currentBannerIndex] || {};
  const bannerImageUrl = currentBanner.MediaURL || '';
  const associatedAssistants = currentBanner.Assistants || []; // Ahora es un array de asistentes

  return (
    <motion.div
      key={currentBannerIndex}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ position: 'relative', paddingTop: '30px', paddingLeft: '20px', paddingRight: '20px' }}>
        <Grid container spacing={2} alignItems="stretch">
          {/* Grid para la imagen de bienvenida */}
          <Grid 
            item 
            xs={12}
            md={6} 
            sx={{ 
              position: 'relative', 
              display: 'flex', 
              justifyContent: { xs: 'center', md: 'flex-start' }, 
              alignItems: 'center' 
            }}
          >
            <Box
              sx={{
                width: { xs: '80%', sm: '100%', md: '100%' },
                position: 'relative',
                paddingTop: '40%',
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 80%), url(${bannerImageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '10px',
              }}
            >
              {/* Texto animado con efecto typewriter */}
              <Typography 
                variant="h3" 
                color="white" 
                align="left" 
                sx={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '10%', 
                  transform: 'translateY(-50%)', 
                  fontSize: { xs: '1rem', sm: '1.5rem', md: '2.5rem' },
                  maxWidth: '80%',
                }}
              >
                <Typewriter
                  words={['¿Qué quieres hacer?', 'Aprende algo nuevo hoy']}
                  loop={0}
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={2000}
                />
              </Typography>
              
              {/* Botón para refrescar manualmente el banner */}
              <Tooltip title="Cambiar Banner">
                <ProtectedButton
                  onClick={handleRefresh}
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    color: 'white',
                    opacity: 0.7,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%', // Asegurando que el botón sea circular
                    padding: 0,
                  }}
                  aria-label="Cambiar Banner"
                >
                  <RefreshIcon fontSize="large" />
                </ProtectedButton>
              </Tooltip>
            </Box>
          </Grid>

          {/* Grid para mostrar asistentes asociados */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: 1,
              position: 'relative',
              zIndex: 2,
              marginLeft: { xs: '0', md: '-80px' },
              flexWrap: 'wrap',
            }}
          >
            {/* Mostrar asistentes asociados */}
            {(
              associatedAssistants && associatedAssistants.length > 0 ? (
                <FlexBox>
                  {associatedAssistants.slice(0, 2).map((asistente) => (
                    <CharacterCard
                      key={asistente.AssistantID}
                      asistente={asistente}
                      variant="header"
                    />
                  ))}
                </FlexBox>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No hay asistentes asociados a este banner.
                </Typography>
              )
            )}
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
});

// Hook personalizado para manejar la lógica y el estado de la página Home
const useHomeData = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [popularAsistentes, setPopularAsistentes] = useState([]);
  const [filteredAsistentes, setFilteredAsistentes] = useState([]);
  const [banners, setBanners] = useState([]); // Estado para almacenar todos los banners
  //const [activeCategoryId, setActiveCategoryId] = useState(null);// Nuevo estado para la categoría activa
  const [currentCategoryId, setCurrentCategoryId] = useState(1); 
  const [activeCategory, setActiveCategory] = useState(null); 
  const navigate = useNavigate();

  // Función para obtener todos los banners
  const fetchAllBanners = useCallback(async () => {
    try {
      const response = await api.get('/principal_banners');
      if (response.status === 200) {
        // Supongamos que cada banner tiene una propiedad 'Assistants' con los asistentes asociados
        setBanners(response.data.banners.map(banner => ({
          ...banner,
          Assistants: banner.Assistants || [], // Asegurarse de que sea un array
        })));
        console.log('Banners obtenidos exitosamente:', response.data.banners);
      }
    } catch (error) {
      console.error('Error al obtener los banners:', error);
      toast.error('Error al obtener los banners.');
    }
  }, []);

  // Función para verificar autenticación, obtener asistentes populares y categorías
  const checkAuth = useCallback(async () => {
    try {
      console.log('Verificando autenticación...');
      const response = await api.get('/check_auth');
      if (response.status === 200) {
        setIsAuthenticated(true);
        console.log('Usuario autenticado');
      }
    } catch (error) {
      console.error('Error en verificación de autenticación:', error);
      setIsAuthenticated(false);
      setShowLoginModal(true); // Abre el modal de login si no está autenticado
    }
  }, []);

  // Función para obtener asistentes populares
  const fetchPopularAsistentes = useCallback(async () => {
    try {
      console.log('Solicitando asistentes populares...');
      const response = await api.get('/asistentes/populares');
      if (response.status === 200) {
        // Mapear las propiedades para mantener consistencia
        const mappedAsistentes = response.data.assistants.map(asistente => ({
          ...asistente,
          TotalConversations: asistente.TotalConversations || asistente.num_conversations || 0,
          TotalFavorites: asistente.num_favorites || 0,
          TotalLikes: asistente.num_likes || 0,
          TypeID: asistente.TypeID || null, // Asegurar que TypeID exista
        }));
        setPopularAsistentes(mappedAsistentes);
        console.log('Asistentes populares recibidos:', mappedAsistentes);
      }
    } catch (error) {
      console.error('Error al obtener asistentes populares:', error);
      toast.error('Error al obtener asistentes populares');
    }
  }, []);

  // Función para obtener categorías
  const fetchCategories = useCallback(async () => {
    try {
      console.log('Solicitando categorías...');
      const response = await api.get('/asistentes/categorias');
      if (response.status === 200) {
        setCategories(response.data.categories);
        console.log('Categorías recibidas:', response.data.categories);
        // Opcional: No establecer una categoría activa inicialmente
        // if (response.data.categories.length > 0) {
        //   setActiveCategoryId(response.data.categories[0].TypeID);
        //   console.log(`Categoría activa establecida a ${response.data.categories[0].TypeID}`);
        // }
      }
    } catch (error) {
      console.error('Error al obtener categorías:', error);
      toast.error('Error al obtener categorías');
    }
  }, []);

  // Obtener los datos al montar el componente
  useEffect(() => {
    checkAuth();
    fetchPopularAsistentes();
    fetchCategories();
    fetchAllBanners(); // Llamamos a fetchAllBanners aquí para obtener los banners al montar
  }, [checkAuth, fetchPopularAsistentes, fetchCategories, fetchAllBanners]);

  // Efecto para filtrar asistentes cuando cambia la categoría activa o los asistentes populares
  useEffect(() => {
    if (activeCategory) {
      const category = categories.find((cat) => cat.TypeID === activeCategory);
      setFilteredAsistentes(category ? category.assistants.slice(0, 6) : []);
      console.log(`Filtrando asistentes por categoría: ${activeCategory}`);
    } else {
      setFilteredAsistentes(popularAsistentes.slice(0, 6));
      console.log('Mostrando asistentes populares sin filtrar');
    }
  }, [activeCategory, categories, popularAsistentes]);

  // Maneja el logout del usuario
  const handleLogout = useCallback(async () => {
    try {
      console.log('Intentando cerrar sesión...');
      const response = await api.post('/logout');
      if (response.status === 200) {
        localStorage.removeItem('session_token'); // Elimina el token de sesión
        setIsAuthenticated(false);
        toast.success('Cierre de sesión exitoso');
        navigate('/'); // Redirige a la página de inicio
        console.log('Sesión cerrada correctamente');
      }
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      toast.error('Error al cerrar sesión');
    }
  }, [navigate]);

  // Maneja el refresco manual del banner
  const handleRefresh = useCallback(() => {
    fetchAllBanners();
    console.log('Banners refrescados manualmente');
  }, [fetchAllBanners]);


  return {
    isAuthenticated,
    showLoginModal,
    handleLoginClose: () => setShowLoginModal(false),
    handleLoginSuccess: () => {
      setIsAuthenticated(true);
      setShowLoginModal(false);
      toast.success('Inicio de sesión exitoso');
    },
    handleLogout,
    triggerLogin: () => setShowLoginModal(true),
    categories,
    popularAsistentes,
    filteredAsistentes,
    banners, // Estado de banners
    handleRefresh,
    setActiveCategory,
    currentCategoryId, // Exponemos la categoría activa
    //handleFilterChange, // Exponemos la función de cambio de filtro
  };
};

// Componente Home: Componente principal de la página de inicio
const Home = React.memo(() => {
  const homeData = useHomeData(); // Obtiene los datos y funciones del hook personalizado
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Utilizado para condicional rendering

  return (
    <ThemeProvider theme={theme}>
      {/* Proveedor de contexto de autenticación */}
      <AuthContext.Provider value={{
        isAuthenticated: homeData.isAuthenticated,
        onLogout: homeData.handleLogout,
        triggerLogin: homeData.triggerLogin,
      }}>
        <ErrorBoundary>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, overflowX: 'hidden' }}>
            {/* Renderiza la Sidebar solo en pantallas grandes y si el usuario está autenticado */}
            {homeData.isAuthenticated && <Sidebar />}
            <Box
              sx={{
                flexGrow: 1,
                minWidth: 0,
                marginLeft: homeData.isAuthenticated && !isSmallScreen ? '300px' : '0',
                transition: 'margin-left 0.3s ease',
              }}
            >
              {/* NavBar: Solo se muestra si el usuario no está autenticado */}
              {!homeData.isAuthenticated && <NavBar />}

              {/* WelcomeSection: ahora recibe todos los banners y asistentes asociados */}
              <WelcomeSection
                asistentes={homeData.popularAsistentes}
                onRefresh={homeData.handleRefresh}
                banners={homeData.banners} // Pasar los banners
              />

              {/* Contenedor Principal con Ancho Ajustado */}
              <Container 
                maxWidth="lg"
                sx={{ 
                  padding: { xs: 1, sm: 2, md: 3 }, 
                  margin: '0 auto',
                }}
              >
                {/* CharacterCarousel: Carrusel de asistentes personalizados */}
                <CharacterCarousel asistentes={homeData.popularAsistentes} title="Para ti" />

                {/* ActionButtons: Botones de acción principal */}
                <ActionButtons />

                {/* CharacterSection - Destacado: Sección de asistentes destacados */}
                <CharacterSection title="Destacado" asistentes={homeData.popularAsistentes} variant="default" />

                {/* CategoryFilters: Filtros por categorías */}
                <CategoryFilters categories={homeData.categories} onFilterChange={homeData.setActiveCategory} />

                {/* CharacterSection - Asistentes: Sección de asistentes filtrados */}
                <CharacterSection title="Asistentes" asistentes={homeData.filteredAsistentes} variant="default" />

                {/* CreateCharacterSection: Sección para crear un nuevo asistente */}
                <CreateCharacterSection />

                {/* CookieConsent: Consentimiento de cookies */}
                <CookieConsent />
              </Container>

              {/* Footer: Pie de página */}
              <Footer />
              {/* Aseguramos que el Footer se alinee con la Navbar ajustando el margen según si está autenticado y el tamaño de la pantalla */}
              {/* Ya ajustado en Footer.js con marginLeft: { sm: '0', md: '300px' } */}

              {/* LoginModal: Modal para iniciar sesión */}
              <LoginModal open={homeData.showLoginModal} onClose={homeData.handleLoginClose} onLoginSuccess={homeData.handleLoginSuccess} />

              {/* Toast Notifications: Contenedor para notificaciones */}
              <ToastContainer 
                position="top-right" 
                autoClose={5000} 
                hideProgressBar={false} 
                newestOnTop={false} 
                closeOnClick 
                rtl={false} 
                pauseOnFocusLoss 
                draggable 
                pauseOnHover 
                style={{ width: '90%', maxWidth: '400px', margin: '0 auto' }}
              />
            </Box>
          </Box>
        </ErrorBoundary>
      </AuthContext.Provider>
    </ThemeProvider>
  );
});

export default Home;
