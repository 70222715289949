// src/components/Admin/EditAsistente.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Grid,
  IconButton,
  Menu,
  ListItemIcon,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PublicIcon from '@mui/icons-material/Public';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import api from '../../api';
import { toast } from 'react-toastify';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import { useParams, useNavigate } from 'react-router-dom';
import './EditAsistente.css'; // Asegúrate de crear este archivo CSS si es necesario

// Definir opciones de visibilidad fuera del componente para evitar recrearlas en cada render
const visibilityOptionsData = [
  {
    label: 'Público',
    value: 1,
    icon: <PublicIcon />,
    description: 'Disponible para que otras personas lo descubran y chateen con él.',
  },
  {
    label: 'Oculto',
    value: 2,
    icon: <LinkIcon />,
    description: 'Disponible para que otros puedan chatear a través del enlace.',
  },
  {
    label: 'Privado',
    value: 0,
    icon: <LockIcon />,
    description: 'Solo visible para ti.',
  },
];

const EditAsistente = () => {
  const { assistantId } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    imagen: null,
    instructions: '',
    voz: '',
    publico: 0, // 0: Privado, 1: Público, 2: Oculto
    TypeID: '',
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [conversationStarters, setConversationStarters] = useState(['']);
  const [conversationCounts, setConversationCounts] = useState([0]);

  // Contadores de caracteres
  const [nombreCount, setNombreCount] = useState(0);
  const [descripcionCount, setDescripcionCount] = useState(0);
  const [instructionsCount, setInstructionsCount] = useState(0);

  // Estado para manejar errores de validación
  const [errors, setErrors] = useState({
    nombre: '',
    descripcion: '',
    imagen: '',
    instructions: '',
    voz: '',
    TypeID: '',
    publico: '',
    conversationStarters: [''],
  });

  // Estado para manejar el menú del dropdown de visibilidad
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // Estado para manejar la selección de visibilidad
  const [selectedVisibility, setSelectedVisibility] = useState({
    label: 'Privado',
    value: 0,
    icon: <LockIcon />,
    description: 'Solo visible para ti.',
  });

  // Opciones de tipos de asistente
  const [assistantTypes, setAssistantTypes] = useState([]);

  // Opciones de voces (mapear desde backend o definir aquí)
  const voiceOptions = useMemo(() => [
    { label: 'Voz Masculina', value: 'en-US-DerekMultilingualNeural' },
    { label: 'Voz Femenina', value: 'en-US-SerenaMultilingualNeural' },
    { label: 'Voz Amigable Masculina', value: 'en-US-DustinMultilingualNeural' },
    { label: 'Voz Amigable Femenina', value: 'en-US-LolaMultilingualNeural' },
    { label: 'Voz Profesional Masculina', value: 'en-US-LewisMultilingualNeural' },
    { label: 'Voz Profesional Femenina', value: 'en-US-NancyMultilingualNeural' },
    // Añadir más opciones según sea necesario
  ], []);

  useEffect(() => {
    // Obtener dinámicamente los tipos de asistente desde la API
    const fetchAssistantTypes = async () => {
      try {
        const response = await api.get('/admin/assistant-types');
        setAssistantTypes(response.data.assistantTypes);
      } catch (error) {
        console.error('Error al obtener tipos de asistente:', error);
        toast.error('Error al obtener tipos de asistente');
      }
    };

    fetchAssistantTypes();
  }, []); // Eliminado visibilityOptions de las dependencias

  useEffect(() => {
    // Cargar datos del asistente
    const fetchAssistantData = async () => {
      try {
        const response = await api.get(`/admin/asistentes/${assistantId}`);
        if (response.status === 200) {
          const data = response.data;
          setFormData({
            nombre: data.Name || '',
            descripcion: data.Description || '',
            imagen: null, // Imagen será opcional para actualizar
            instructions: data.Instructions || '',
            voz: data.Voice || '',
            publico: data.Publico || 0,
            TypeID: data.TypeID || '',
          });
          setConversationStarters(data.InitialPrompt || ['']);
          setConversationCounts(data.InitialPrompt ? data.InitialPrompt.map(() => 0) : [0]);
          setImagePreview(data.ImageURL || null);

          // Establecer la visibilidad seleccionada
          const visibility = visibilityOptionsData.find(opt => opt.value === data.Publico);
          if (visibility) {
            setSelectedVisibility(visibility);
          }

          // Establecer contadores de caracteres
          setNombreCount(data.Name ? data.Name.length : 0);
          setDescripcionCount(data.Description ? data.Description.length : 0);
          setInstructionsCount(data.Instructions ? data.Instructions.length : 0);
        } else {
          toast.error('Asistente no encontrado');
          navigate('/myassistants');
        }
      } catch (error) {
        console.error('Error al cargar datos del asistente:', error);
        toast.error('Error al cargar los datos del asistente');
      } finally {
        setLoading(false);
      }
    };

    fetchAssistantData();
  }, [assistantId, navigate]); // Eliminado visibilityOptions de las dependencias

  const handleChange = (e, index = null) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      const file = files[0];
      console.log('Archivo seleccionado:', file); // Log para depuración
      setFormData(prev => ({ ...prev, imagen: file }));
      setImagePreview(URL.createObjectURL(file));
      validateField(name, file, index);
    } else {
      if (name.startsWith('conversation_') && index !== null) {
        const newStarters = [...conversationStarters];
        newStarters[index] = value.slice(0, 150); // Limitar a 150 caracteres
        setConversationStarters(newStarters);

        // Actualizar contador
        const newCounts = [...conversationCounts];
        newCounts[index] = value.length;
        setConversationCounts(newCounts);

        // Validar campo
        validateField(name, value, index);
      } else {
        setFormData(prev => ({ ...prev, [name]: value }));
        console.log(`Campo ${name} actualizado a:`, value); // Log para depuración

        // Actualizar contadores
        if (name === 'nombre') setNombreCount(value.length);
        if (name === 'descripcion') setDescripcionCount(value.length);
        if (name === 'instructions') setInstructionsCount(value.length);

        // Validar campo
        validateField(name, value);
      }
    }
  };

  const handleVisibilityClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVisibilityClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVisibility = (option) => {
    setSelectedVisibility(option);
    setFormData(prev => ({ ...prev, publico: option.value }));
    validateField('publico', option.value);
    handleVisibilityClose();
  };

  const handleAddConversationStarter = () => {
    if (conversationStarters.length < 2) {
      setConversationStarters([...conversationStarters, '']);
      setConversationCounts([...conversationCounts, 0]);
      setErrors(prev => ({
        ...prev,
        conversationStarters: [...prev.conversationStarters, ''],
      }));
    }
  };

  const handleRemoveConversationStarter = (index) => {
    const newStarters = conversationStarters.filter((_, i) => i !== index);
    setConversationStarters(newStarters);

    const newCounts = conversationCounts.filter((_, i) => i !== index);
    setConversationCounts(newCounts);

    const newErrors = errors.conversationStarters.filter((_, i) => i !== index);
    setErrors(prev => ({
      ...prev,
      conversationStarters: newErrors,
    }));
  };

  const validateField = (name, value, index = null) => {
    let error = '';

    const requiredStringFields = ['nombre', 'descripcion', 'instructions', 'voz'];
    const requiredSelectFields = ['TypeID', 'publico'];

    if (requiredStringFields.includes(name)) {
      if (!value || (typeof value === 'string' && value.trim() === '')) {
        error = 'Este campo es obligatorio.';
      }
    }

    if (requiredSelectFields.includes(name)) {
      if (value === '' || value === null || value === undefined) {
        error = 'Este campo es obligatorio.';
      }
    }

    // Validación para iniciadores de conversación
    if (name.startsWith('conversation_') && index !== null) {
      if (!value || (typeof value === 'string' && value.trim() === '')) {
        error = 'Este campo es obligatorio.';
      }
      const updatedErrors = [...errors.conversationStarters];
      updatedErrors[index] = error;
      setErrors(prev => ({
        ...prev,
        conversationStarters: updatedErrors,
      }));
      return;
    }

    // Asignar el error al campo correspondiente
    if (requiredStringFields.includes(name) || requiredSelectFields.includes(name)) {
      setErrors(prev => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      nombre: '',
      descripcion: '',
      imagen: '',
      instructions: '',
      voz: '',
      TypeID: '',
      publico: '',
      conversationStarters: [],
    };

    // Validar campos string
    ['nombre', 'descripcion', 'instructions', 'voz'].forEach(field => {
      if (!formData[field] || (typeof formData[field] === 'string' && formData[field].trim() === '')) {
        newErrors[field] = 'Este campo es obligatorio.';
        valid = false;
      }
    });

    // Validar campos de selección
    ['TypeID', 'publico'].forEach(field => {
      if (formData[field] === '' || formData[field] === null || formData[field] === undefined) {
        newErrors[field] = 'Este campo es obligatorio.';
        valid = false;
      }
    });

    // Validar imagen
    if (!formData.imagen && !imagePreview) { // Permitir mantener la imagen existente
      newErrors.imagen = 'Este campo es obligatorio.';
      valid = false;
    }

    // Validar iniciadores de conversación
    conversationStarters.forEach((starter, index) => {
      if (!starter || (typeof starter === 'string' && starter.trim() === '')) {
        newErrors.conversationStarters[index] = 'Este campo es obligatorio.';
        valid = false;
      } else {
        newErrors.conversationStarters[index] = '';
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Por favor, corrige los errores en el formulario.');
      return;
    }

    setIsSubmitting(true);

    const data = new FormData();
    data.append('nombre', formData.nombre);
    data.append('descripcion', formData.descripcion);
    data.append('instructions', formData.instructions);
    data.append('voz', formData.voz);
    data.append('publico', formData.publico);
    data.append('TypeID', formData.TypeID);

    // Añadir todos los iniciadores de conversación
    conversationStarters.forEach((starter) => {
      data.append('initial_prompts[]', starter);
    });

    if (formData.imagen) {
      console.log('Añadiendo imagen al FormData:', formData.imagen); // Log para depuración
      data.append('imagen', formData.imagen);
    }

    try {
      const response = await api.put(`/admin/asistentes/${assistantId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        toast.success('Asistente actualizado exitosamente');
        navigate('/myassistants');
      } else {
        toast.error('Error al actualizar el asistente');
      }
    } catch (error) {
      console.error('Error al actualizar asistente:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error al actualizar el asistente.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box className="loading-container" role="status" aria-live="polite">
        <CircularProgress aria-label="Cargando asistente" />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f2f2f2', minHeight: '100vh' }}>
      <SidebarCreateAsistente />
      <Box
        sx={{
          marginLeft: { sm: '250px' }, // Ancho del Drawer permanente
          padding: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#f2f2f2',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          className="form-container"
          sx={{
            width: { xs: '100%', sm: '60%' }, // Full width en móviles
            maxWidth: '800px',
            margin: '0 auto',
            padding: '30px',
            backgroundColor: '#ffffff',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            fontFamily: 'Roboto, sans-serif',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Editar Asistente
          </Typography>

          {/* Sección de Foto de Perfil del Asistente */}
          <Box
            className="image-container"
            sx={{
              position: 'relative',
              width: '120px',
              height: '120px',
              margin: '0 auto 10px auto',
              border: '2px solid #e0e0e0',
              borderRadius: '50%',
              overflow: 'hidden',
              backgroundColor: '#f9f9f9',
              transition: 'transform 0.3s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => document.getElementById('imagen-input').click()}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Previsualización del Asistente"
                className="image-preview"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <Typography
                variant="body2"
                color="#555555"
                sx={{
                  textAlign: 'center',
                  paddingTop: '40px',
                  fontSize: '14px',
                }}
              >
                Foto de Avatar
              </Typography>
            )}
            {/* Ícono de Edición */}
            <IconButton
              component="label"
              sx={{
                position: 'absolute',
                bottom: '0px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#ffffff',
                border: '1px solid #ffffff',
                padding: '2px',
                '&:hover': {
                  backgroundColor: '#ffffff',
                },
              }}
              size="small"
            >
              <EditIcon sx={{ width: '20px', height: '20px', color: '#333333' }} />
              <input
                id="imagen-input"
                type="file"
                accept="image/*"
                hidden
                name="imagen"
                onChange={handleChange}
              />
            </IconButton>
          </Box>
          {/* Texto "Foto de Avatar" */}
          <Typography
            variant="body2"
            color="#555555"
            sx={{
              textAlign: 'center',
              marginBottom: '20px',
              fontSize: '14px',
            }}
          >
            Foto de Avatar
          </Typography>

          {/* Campo de Texto: Nombre del Asistente */}
          <Box sx={{ position: 'relative', width: '100%', textAlign: 'left', marginBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '5px',
                color: '#333333',
              }}
            >
              Nombre del Asistente <span style={{ color: '#ff4d4f' }}>*</span>
            </Typography>
            <TextField
              name="nombre"
              variant="outlined"
              value={formData.nombre}
              onChange={handleChange}
              required
              className="text-field"
              placeholder="Por ejemplo, Albert Einstein"
              InputProps={{
                style: {
                  backgroundColor: '#ffffff',
                  borderRadius: '4px',
                  padding: '8px',
                  color: '#333333',
                },
              }}
              sx={{
                width: '100%',
                transition: 'all 0.3s ease-in-out',
                border: errors.nombre ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
                borderRadius: '4px',
              }}
            />
            {errors.nombre && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                {errors.nombre}
              </Typography>
            )}
            {/* Contador de Caracteres */}
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                right: '0px',
                bottom: '-18px',
                color: nombreCount > 25 ? '#ff4d4f' : '#888888',
                fontSize: '12px',
              }}
            >
              {nombreCount}/25
            </Typography>
          </Box>

          {/* Campo de Texto: Descripción del Comportamiento del Asistente */}
          <Box sx={{ width: '100%', marginBottom: '20px', position: 'relative' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '5px',
                color: '#333333',
              }}
            >
              Descripción <span style={{ color: '#ff4d4f' }}>*</span>
            </Typography>
            <TextField
              name="descripcion"
              multiline
              rows={4}
              variant="outlined"
              value={formData.descripcion}
              onChange={handleChange}
              required
              className="text-field"
              placeholder="Añade una breve descripción sobre lo que hace este avatar."
              sx={{
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                padding: '8px',
                color: '#333333',
                transition: 'all 0.3s ease-in-out',
                border: errors.descripcion ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
              }}
            />
            {errors.descripcion && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                {errors.descripcion}
              </Typography>
            )}
            {/* Contador de Caracteres */}
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                right: '0px',
                bottom: '-18px',
                color: descripcionCount > 150 ? '#ff4d4f' : '#888888',
                fontSize: '12px',
              }}
            >
              {descripcionCount}/150
            </Typography>
          </Box>

          {/* Campo de Instrucciones */}
          <Box sx={{ width: '100%', marginBottom: '20px', position: 'relative' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '5px',
                color: '#333333',
              }}
            >
              Instrucciones <span style={{ color: '#ff4d4f' }}>*</span>
            </Typography>
            <TextField
              name="instructions"
              multiline
              rows={6}
              variant="outlined"
              value={formData.instructions}
              onChange={handleChange}
              required
              className="text-field"
              placeholder="¿Qué hace este avatar? ¿Cómo se comporta? ¿Qué debería evitar hacer?"
              sx={{
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                padding: '8px',
                color: '#333333',
                transition: 'all 0.3s ease-in-out',
                border: errors.instructions ? '2px solid #ff4d4f' : '2px solid #e0e0e0',
              }}
            />
            {errors.instructions && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                {errors.instructions}
              </Typography>
            )}
            {/* Contador de Caracteres */}
            <Typography
              variant="caption"
              sx={{
                position: 'absolute',
                right: '0px',
                bottom: '-18px',
                color: instructionsCount > 3000 ? '#ff4d4f' : '#888888',
                fontSize: '12px',
              }}
            >
              {instructionsCount}/3000
            </Typography>
          </Box>

          {/* Sección: Iniciadores de Conversación */}
          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '5px',
                color: '#333333',
              }}
            >
              Iniciadores de Conversación <span style={{ color: '#ff4d4f' }}>*</span>
            </Typography>
            {conversationStarters.map((starter, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  animation: 'slideDown 0.3s ease-in-out',
                }}
              >
                <TextField
                  name={`conversation_${index}`}
                  value={starter}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Escribe una frase de inicio..."
                  inputProps={{ maxLength: 150 }}
                  variant="outlined"
                  required
                  className="conversation-starter-input"
                  sx={{
                    width: '90%',
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    padding: '8px',
                    color: '#333333',
                    transition: 'all 0.3s ease-in-out',
                    border: errors.conversationStarters[index] ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
                  }}
                />
                <IconButton
                  onClick={() => handleRemoveConversationStarter(index)}
                  sx={{
                    marginLeft: '10px',
                    backgroundColor: '#333333',
                    color: '#ffffff',
                    border: '1px solid #222222',
                    borderRadius: '4px',
                    padding: '0',
                    '&:hover': {
                      backgroundColor: '#555555',
                    },
                    width: '30px',
                    height: '30px',
                  }}
                >
                  <CloseIcon sx={{ fontSize: '16px' }} />
                </IconButton>
                {/* Contador de Caracteres */}
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: '10px',
                    color: starter.length > 150 ? '#ff4d4f' : '#888888',
                    fontSize: '12px',
                  }}
                >
                  {conversationCounts[index]}/150
                </Typography>
                {/* Validación de iniciadores de conversación */}
                {errors.conversationStarters[index] && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff4d4f',
                      fontSize: '12px',
                      marginTop: '5px',
                      width: '100%',
                    }}
                  >
                    {errors.conversationStarters[index]}
                  </Typography>
                )}
              </Box>
            ))}
            {conversationStarters.length < 2 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={handleAddConversationStarter}
                  sx={{
                    backgroundColor: '#333333',
                    color: '#ffffff',
                    borderRadius: '15px',
                    padding: '10px 20px',
                    fontSize: '14px',
                    fontFamily: 'Roboto, sans-serif',
                    textTransform: 'none',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                      backgroundColor: '#555555',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    },
                    transition: 'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  }}
                >
                  Añadir frase de inicio
                </Button>
              </Box>
            )}
          </Box>

          {/* Seleccionar Voz y Tipo de Asistente */}
          <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
            {/* Seleccionar Voz del Asistente */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="voz-label" sx={{ color: '#333333' }}>
                  Seleccionar Voz del Asistente <span style={{ color: '#ff4d4f' }}>*</span>
                </InputLabel>
                <Select
                  labelId="voz-label"
                  name="voz"
                  value={formData.voz}
                  label="Seleccionar Voz del Asistente"
                  onChange={handleChange}
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    color: '#333333',
                    border: errors.voz ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
                    '& .MuiSelect-icon': { color: '#333333' },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #388E3C',
                    },
                  }}
                >
                  {voiceOptions.map((voice) => (
                    <MenuItem key={voice.value} value={voice.value}>
                      {voice.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.voz && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff4d4f',
                      fontSize: '12px',
                      marginTop: '5px',
                    }}
                  >
                    {errors.voz}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {/* Seleccionar Tipo de Asistente */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="type-label" sx={{ color: '#333333' }}>
                  Tipo de Asistente <span style={{ color: '#ff4d4f' }}>*</span>
                </InputLabel>
                <Select
                  labelId="type-label"
                  name="TypeID"
                  value={formData.TypeID}
                  label="Tipo de Asistente"
                  onChange={handleChange}
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '4px',
                    color: '#333333',
                    border: errors.TypeID ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
                    '& .MuiSelect-icon': { color: '#333333' },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #388E3C',
                    },
                  }}
                >
                  {assistantTypes.map((type) => (
                    <MenuItem key={type.TypeID} value={type.TypeID}>
                      {type.TypeName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.TypeID && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff4d4f',
                      fontSize: '12px',
                      marginTop: '5px',
                    }}
                  >
                    {errors.TypeID}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* Dropdown de Visibilidad Personalizado */}
          <Box sx={{ width: '100%', marginBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '5px',
                color: '#333333',
              }}
            >
              Visibilidad <span style={{ color: '#ff4d4f' }}>*</span>
            </Typography>
            <Button
              onClick={handleVisibilityClick}
              variant="outlined"
              startIcon={selectedVisibility.icon}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                border: errors.publico ? '1px solid #ff4d4f' : '1px solid #e0e0e0',
                textAlign: 'left',
                padding: '8px 16px',
                color: '#333333',
                '&:hover': {
                  border: '1px solid #388E3C',
                },
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {visibilityOptionsData.find(option => option.value === formData.publico)?.label || 'Selecciona una opción'}
                </Typography>
                <Typography variant="caption" sx={{ color: '#888888' }}>
                  {visibilityOptionsData.find(option => option.value === formData.publico)?.description || ''}
                </Typography>
              </Box>
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleVisibilityClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiPaper-root': {
                  width: '100%',
                  maxWidth: '400px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              {visibilityOptionsData.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleSelectVisibility(option)}
                  selected={formData.publico === option.value}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '16px',
                    '&.Mui-selected': {
                      backgroundColor: '#e0e0e0',
                    },
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
                      {option.icon}
                    </ListItemIcon>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'left' }}>
                      {option.label}
                    </Typography>
                  </Box>
                  <Typography variant="caption" sx={{ color: '#888888', textAlign: 'left', width: '100%' }}>
                    {option.description}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            {errors.publico && (
              <Typography
                variant="caption"
                sx={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  marginTop: '5px',
                }}
              >
                {errors.publico}
              </Typography>
            )}
          </Box>

          {/* Botón de Actualización del Asistente */}
          <Box className="submit-button" sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: '220px',
                height: '50px',
                backgroundColor: '#333333',
                border: '1px solid #222222',
                color: '#ffffff',
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '16px',
                fontFamily: 'Roboto, sans-serif',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  backgroundColor: '#555555',
                  border: '1px solid #111111',
                  boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
                },
                '&:focus': {
                  outline: '2px solid #3b82f6',
                },
                transition: 'background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Actualizando asistente...' : 'Actualizar Asistente'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditAsistente;
