// src/components/ErrorBoundary.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para renderizar la interfaz de respaldo
    return { hasError: true, errorInfo: error };
  }

  componentDidCatch(error, errorInfo) {
    // Puedes registrar el error en un servicio de reporte de errores
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  handleReload = () => {
    // Recargar la página para intentar restaurar el estado
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
            textAlign: 'center',
            padding: 2,
          }}
        >
          <Typography variant="h4" color="error" gutterBottom>
            Algo salió mal.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Estamos trabajando para solucionarlo. Por favor, intenta recargar la página.
          </Typography>
          <Button variant="contained" color="primary" onClick={this.handleReload}>
            Recargar Página
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
