// src/components/Admin/Reportes.js
import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import api from '../../api';
import { toast } from 'react-toastify';

const Reportes = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchReportes = async () => {
    try {
      const response = await api.get('/admin/reportes');
      setData(response.data); // Asegúrate de que la data esté en el formato correcto para Recharts
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener reportes:', error);
      toast.error('Error al obtener reportes.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportes();
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Reportes
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data}>
            <XAxis dataKey="mes" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="cantidad" fill="#4299e1" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Reportes;
