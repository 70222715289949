// src/components/Admin/Dashboard.js

import React, { useEffect, useState } from 'react';
import { Typography, Grid, Paper, CircularProgress, Box } from '@mui/material';
import api from '../../api';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchMetrics = async () => {
    try {
      const response = await api.get('/admin/dashboard-metrics'); // Asegúrate de que api.js está configurado correctamente
      setMetrics(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener métricas:', error);
      toast.error('Error al obtener métricas.');
      // Establecer métricas por defecto para evitar errores en el renderizado
      setMetrics({
        usuariosActivos: 0,
        entrevistasRealizadas: 0,
        reportesGenerados: 0
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics) {
    return (
      <Box>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="body1" color="error">
          No se pudieron cargar las métricas.
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        {/* Número de Usuarios Activos */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">Usuarios Activos</Typography>
            <Typography variant="h4">{metrics.usuariosActivos}</Typography>
          </Paper>
        </Grid>
        {/* Entrevistas Realizadas */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">Entrevistas Realizadas</Typography>
            <Typography variant="h4">{metrics.entrevistasRealizadas}</Typography>
          </Paper>
        </Grid>
        {/* Reportes Generados */}
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6">Reportes Generados</Typography>
            <Typography variant="h4">{metrics.reportesGenerados}</Typography>
          </Paper>
        </Grid>
      </Grid>
      {/* Aquí puedes agregar gráficos utilizando bibliotecas como Recharts o Chart.js */}
    </div>
  );
};

export default Dashboard;
