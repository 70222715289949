// src/components/Admin/Entrevistas.js
import React from 'react';
import { Typography } from '@mui/material';

const Entrevistas = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Entrevistas
      </Typography>
      {/* Implementar la lógica de visualización de entrevistas */}
      <Typography>Contenido de entrevistas...</Typography>
    </div>
  );
};

export default Entrevistas;
