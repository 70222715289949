import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from '@mui/material';
import api from '../api';
import { toast } from 'react-toastify';

const CreateBanner = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/asistentes/categorias');
        setCategories(response.data.categories);
      } catch (error) {
        console.error('Error al obtener categorías:', error);
        toast.error('Error al obtener categorías');
      }
    };
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCategory || !imageFile) {
      toast.error('Por favor, selecciona una categoría y una imagen');
      return;
    }
    const formData = new FormData();
    formData.append('category_id', selectedCategory);
    formData.append('image', imageFile);

    try {
      await api.post('/create_banner', formData);
      toast.success('Banner creado exitosamente');
    } catch (error) {
      console.error('Error al crear banner:', error);
      toast.error('Error al crear el banner');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5">Crear Banner</Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Categoría</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          required
        >
          {categories.map((cat) => (
            <MenuItem key={cat.TypeID} value={cat.TypeID}>
              {cat.TypeName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" component="label" sx={{ mt: 2 }}>
        Subir Imagen
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={(e) => setImageFile(e.target.files[0])}
        />
      </Button>
      {imageFile && <Typography>{imageFile.name}</Typography>}
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Crear Banner
      </Button>
    </form>
  );
};

export default CreateBanner;
