// src/components/ConfirmEmailPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api'; // Asegúrate de que api está correctamente importado

const ConfirmEmailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState('');

  // Extraer el token de la URL cuando el componente se monta
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get('token');
    if (tokenParam) {
      confirmEmail(tokenParam);
    } else {
      setFeedback('Token inválido.');
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Función para confirmar el email
  const confirmEmail = async (token) => {
    try {
      const response = await api.post('/confirm_email', { token });
      setFeedback(response.data.message || 'Cuenta confirmada exitosamente.');
      setLoading(false);
      // Redirigir a /home después de unos segundos
      setTimeout(() => {
        navigate('/');
      }, 1000); 
    } catch (error) {
      if (error.response && error.response.data.error) {
        setFeedback(error.response.data.error);
      } 
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: { xs: 4, sm: 8 }, mb: { xs: 4, sm: 8 } }}>
      <Card elevation={6}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Confirmación de Cuenta
          </Typography>
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
              <Typography variant="body1" sx={{ mt: 2 }}>
                Confirmando tu cuenta...
              </Typography>
            </div>
          ) : (
            <>
              {feedback && (
                <Alert
                  severity={feedback.toLowerCase().includes('exitosamente') ? 'success' : 'error'}
                  sx={{ mb: 2 }}
                >
                  {feedback}
                </Alert>
              )}
              {feedback.toLowerCase().includes('exitosamente') && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate('/home')}
                >
                  Ir a Inicio
                </Button>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ConfirmEmailPage;
