// frontend/src/components/Auth/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { toast } from 'react-toastify';
import './Auth.css';

const VerifyEmail = () => {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const [status, setStatus] = useState('Verificando...');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            if (!token) {
                setStatus('Token de verificación inválido.');
                toast.error('Token de verificación inválido.');
                return;
            }
            try {
                const response = await api.get(`/confirm/${token}`);
                setStatus(response.data.message);
                toast.success(response.data.message);

                // Guardar el token de sesión en localStorage
                const { session_token } = response.data;
                localStorage.setItem('session_token', session_token);

                // Redirigir al home
                navigate('/home');
            } catch (error) {
                if (error.response && error.response.data.error) {
                    setStatus(error.response.data.error);
                    toast.error(error.response.data.error);
                } else {
                    setStatus('Error al verificar el correo electrónico.');
                    toast.error('Error al verificar el correo electrónico.');
                }
            }
        };
        verifyEmail();
    }, [token, navigate]);

    return (
        <div className="auth-container">
            <h2>Verificación de Correo Electrónico</h2>
            <p>{status}</p>
        </div>
    );
};

export default VerifyEmail;
