// src/components/Admin/SidebarCreateAsistente.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Link,
  Drawer,
  Typography,
  AppBar,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Explore as ExploreIcon,
  PersonAdd as PersonAddIcon,
  Home as HomeIcon,
  Settings as SettingsIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api';
import './SidebarCreateAsistente.css';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileSettingsPopup from '../ProfileSettingsPopup';
import Logo from '../Logo'; // Importamos el componente Logo

const SidebarCreateAsistente = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [recentAssistants, setRecentAssistants] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMobileOpen(false);
  };

  const currentPath = location.pathname;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [favoritesResponse, recentsResponse, userInfoResponse] = await Promise.all([
          api.get('user/favorites'),
          api.get('user/recent-assistants'),
          api.get('user/info'),
        ]);

        if (favoritesResponse.status === 200) {
          setFavorites(favoritesResponse.data.favorites);
        }

        if (recentsResponse.status === 200) {
          setRecentAssistants(recentsResponse.data.recentAssistants);
        }

        if (userInfoResponse.status === 200) {
          setUserInfo(userInfoResponse.data);
        }
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
        toast.error('Error al obtener datos del usuario');
      }
    };

    fetchUserData();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const response = await api.post('/logout');
      if (response.status === 200) {
        navigate('/');
      }
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const handleSettingsClick = () => {
    setIsPopupOpen(true);
    handleMenuClose();
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const refreshUserInfo = async () => {
    try {
      const userInfoResponse = await api.get('user/info');
      if (userInfoResponse.status === 200) {
        setUserInfo(userInfoResponse.data);
      }
    } catch (error) {
      console.error('Error al refrescar datos del usuario:', error);
      toast.error('Error al refrescar datos del usuario');
    }
  };

  const drawerWidth = 240; // Ancho del Sidebar

  const drawer = (
    <Box
      sx={{
        width: drawerWidth,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'hidden',
      }}
      role="presentation"
    >
      <Box className="sidebar-scrollable" sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <List className="sidebar-list">
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation('/')}
              selected={currentPath === '/'}
            >
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation('/createavatar')}
              selected={currentPath === '/createavatar'}
            >
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Crear Asistente" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation('/discovery')}
              selected={currentPath === '/discovery'}
            >
              <ListItemIcon>
                <ExploreIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Descubrir" />
            </ListItemButton>
          </ListItem>

          {/* Nuevo Ítem de Menú para "Mis Asistentes" */}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => handleNavigation('/myassistants')}
              selected={currentPath === '/myassistants'}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Mis Asistentes" />
            </ListItemButton>
          </ListItem>

          {/* Últimas Conversaciones */}
          <Box className="sidebar-section">
            <Typography variant="h6" className="section-title">
              Últimas Conversaciones
            </Typography>
            <Box
              className={`assistant-list ${
                isMobile ? 'assistant-list-mobile' : 'assistant-list-desktop'
              }`}
            >
              {recentAssistants.length > 0 ? (
                <List>
                  {recentAssistants.map((assistant) => (
                    <ListItem key={assistant.AssistantID} disablePadding>
                      <ListItemButton
                        onClick={() =>
                          handleNavigation(`/assistant/${assistant.AssistantID}`)
                        }
                      >
                        <ListItemIcon>
                          <Avatar
                            src={assistant.ImageURL}
                            sx={{ width: 30, height: 30 }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={assistant.Name}
                          primaryTypographyProps={{
                            noWrap: false,
                            sx: {
                              wordBreak: 'break-word',
                              whiteSpace: 'normal',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">
                  No tienes asistentes recientes.
                </Typography>
              )}
            </Box>
          </Box>

          <Divider className="section-divider" />

          {/* Favoritos */}
          <Box className="sidebar-section">
            <Typography variant="h6" className="section-title">
              Favoritos
            </Typography>
            <Box
              className={`assistant-list ${
                isMobile ? 'assistant-list-mobile' : 'assistant-list-desktop'
              }`}
            >
              {favorites.length > 0 ? (
                <List>
                  {favorites.map((assistant) => (
                    <ListItem key={assistant.AssistantID} disablePadding>
                      <ListItemButton
                        onClick={() =>
                          handleNavigation(`/assistant/${assistant.AssistantID}`)
                        }
                      >
                        <ListItemIcon>
                          <Avatar
                            src={assistant.ImageURL}
                            sx={{ width: 30, height: 30 }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={assistant.Name}
                          primaryTypographyProps={{
                            noWrap: false,
                            sx: {
                              wordBreak: 'break-word',
                              whiteSpace: 'normal',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2">
                  No tienes asistentes favoritos.
                </Typography>
              )}
            </Box>
          </Box>
        </List>
      </Box>

      {/* Usuario */}
      <Divider />
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleMenuClick}
      >
        <Avatar
          src={userInfo.ProfileImage}
          sx={{
            bgcolor: userInfo.ProfileImage ? 'transparent' : 'lightyellow',
            color: 'black',
            marginRight: '10px',
          }}
        >
          {userInfo.ProfileImage
            ? null
            : userInfo.UserName?.charAt(0).toUpperCase()}
        </Avatar>
        <Typography variant="body1">{userInfo.UserName}</Typography>
      </Box>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleNavigation('/myassistants');
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Mis Avatares
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Configuración
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            handleLogout();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>

      {/* Footer */}
      <Divider />
      <Box sx={{ padding: '10px' }}>
        <Link href="/privacy" variant="body2" className="footer-link">
          Política de Privacidad
        </Link>
        <br />
        <Link href="/terms" variant="body2" className="footer-link">
          Condiciones de Uso
        </Link>
      </Box>

      {/* Profile Settings Popup */}
      <ProfileSettingsPopup
        open={isPopupOpen}
        handleClose={handlePopupClose}
        userInfo={userInfo}
        refreshUserInfo={refreshUserInfo}
      />
    </Box>
  );

  return (
    <>
      {/* AppBar para Móvil */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          display: { sm: 'none' },
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            {isMobileOpen ? (
              <CloseIcon sx={{ color: 'black' }} />
            ) : (
              <MenuIcon sx={{ color: 'black' }} />
            )}
          </IconButton>
          <Logo />
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Box component="nav">
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? isMobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Mejorar rendimiento en móviles
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              overflowX: 'hidden',
              ...(isMobile && {
                top: 0,
                height: '100%',
              }),
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SidebarCreateAsistente;
