// src/components/Assistant/Discovery.js

import React, { useEffect, useState, memo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Tooltip,
  Badge,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Pagination,
  Slider,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { toast } from 'react-toastify';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import { format } from 'date-fns';
import './Discovery.css';
import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CategoryIcon from '@mui/icons-material/Category';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { debounce } from 'lodash';

// Styled Badge for Favorite Assistants
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#ff5722', // Color naranja para destacar
    color: 'white',
    borderRadius: '4px',
    fontSize: '0.75rem',
  },
}));

// Función para generar colores pastel basados en TypeID
const generatePastelColor = (typeId) => {
  const colors = [
    '#AEDFF7', // Azul pastel
    '#F7E6AD', // Amarillo pastel
    '#F7ADAD', // Rojo pastel
    '#ADF7BD', // Verde pastel
    '#F7C0AD', // Naranja pastel
    '#D1ADF7', // Morado pastel
    '#F7D1AD', // Marrón pastel
    '#ADF7F0', // Turquesa pastel
    '#F7ADF7', // Rosa pastel
    '#ADF7F7', // Cyan pastel
  ];
  return colors[typeId % colors.length];
};

// Función para obtener la etiqueta de categoría con color distintivo
const getCategoryChip = (typeId, categoryName) => (
  <Chip
    icon={<CategoryIcon />}
    label={categoryName}
    size="small"
    variant="outlined"
    style={{
      backgroundColor: generatePastelColor(typeId),
      color: '#333333',
      fontWeight: 500,
      fontSize: '0.75rem',
    }}
    className="category-chip"
  />
);

// Función para determinar el color de las métricas
const getMetricColor = (type, value) => {
  if (type === 'likes' && value > 100) return '#4caf50'; // Verde para más de 100 likes
  if (type === 'conversations' && value > 1000) return '#2196f3'; // Azul para más de 1000 conversaciones
  return '#757575'; // Gris neutro
};

// Componente AsistenteCard Memoizado
const AsistenteCard = memo(({ assistant, handleChat }) => {
  const navigate = useNavigate();

  // Función para obtener el icono según el tipo de asistente
  const getAssistantTypeIcon = (publico) => {
    switch (publico) {
      case 0:
        return <LockIcon fontSize="small" color="action" />;
      case 1:
        return <PublicIcon fontSize="small" color="action" />;
      case 2:
        return <VisibilityOffIcon fontSize="small" color="action" />;
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className="assistant-card">
        <CardContent className="card-content">
          {/* Sección Superior: Avatar y Categoría */}
          <Box className="top-section">
            {/* Avatar */}
            <Avatar
              src={assistant.ImageURL}
              alt={`Avatar de ${assistant.Name}`}
              className="assistant-avatar"
              aria-label={`Avatar de ${assistant.Name}`}
              loading="lazy"
            >
              {assistant.ImageURL
                ? null
                : assistant.Name?.charAt(0).toUpperCase()}
            </Avatar>

            {/* Etiqueta del Rol */}
            <Box className="role-tag">
              {getCategoryChip(assistant.TypeID, assistant.TypeName)}
            </Box>
          </Box>

          {/* Información del Asistente */}
          <Box className="info-section">
            {/* Nombre del Asistente */}
            <Typography
              variant="h6"
              component="div"
              className="assistant-name"
              onClick={() => navigate(`/assistant/${assistant.AssistantID}`)}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') navigate(`/assistant/${assistant.AssistantID}`);
              }}
              aria-label={`Ver detalles de ${assistant.Name}`}
            >
              {assistant.Name}
            </Typography>

            {/* @Username del Creador */}
            <Typography
              variant="body2"
              color="text.secondary"
              className="creator-username"
            >
              @{assistant.CreatedBy}
            </Typography>

            {/* Fecha de Creación */}
            <Typography
              variant="body2"
              color="text.secondary"
              className="creation-date"
            >
              {assistant.CreationDate
                ? format(new Date(assistant.CreationDate), 'dd/MM/yyyy')
                : 'N/A'}
            </Typography>

            {/* Descripción del Asistente */}
            {assistant.Description && (
              <Typography
                variant="body2"
                color="text.primary"
                className="assistant-description"
              >
                {assistant.Description}
              </Typography>
            )}
          </Box>

          {/* Detalles del Asistente */}
          <Box className="details-section">
            <Grid container spacing={1}>
              {/* Conversaciones */}
              <Grid item xs={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('conversations', assistant.num_conversations) }}>
                  <ChatBubbleOutlineIcon fontSize="small" aria-label="Conversaciones" />
                  <Typography variant="caption">
                    {assistant.num_conversations > 1000 ? '+1000' : assistant.num_conversations}
                  </Typography>
                </Box>
              </Grid>
              {/* Me gusta */}
              <Grid item xs={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('likes', assistant.num_likes) }}>
                  <ThumbUpAltOutlinedIcon fontSize="small" aria-label="Likes" />
                  <Typography variant="caption">
                    {assistant.num_likes > 100 ? '+100' : assistant.num_likes}
                  </Typography>
                </Box>
              </Grid>
              {/* Favoritos */}
              <Grid item xs={4} className="metric-item">
                <Box className="metric-box" style={{ color: getMetricColor('favorites', assistant.num_favorites) }}>
                  <FavoriteIcon fontSize="small" aria-label="Favoritos" />
                  <Typography variant="caption">
                    {assistant.num_favorites > 500 ? '+500' : assistant.num_favorites}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Botón de Chat */}
          <Box className="chat-button-container">
            <Tooltip title="Iniciar Chat" arrow>
              <Button
                size="small"
                variant="contained"
                className="chat-button"
                onClick={() => handleChat(assistant.AssistantID)}
                startIcon={<ChatBubbleOutlineIcon />}
                aria-label={`Iniciar chat con ${assistant.Name}`}
              >
                Chat
              </Button>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
});

const Discovery = () => {
  const [assistants, setAssistants] = useState([]);
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState('date-newest');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [assistantsPerPage] = useState(27); // Número de asistentes por página
  const [favoritesRange, setFavoritesRange] = useState([0, 500]); // Rango de favoritos
  const [likesRange, setLikesRange] = useState([0, 100]); // Rango de likes
  const [conversationsRange, setConversationsRange] = useState([0, 1000]); // Rango de conversaciones
  const navigate = useNavigate();

  // Función para manejar el chat
  const handleChat = (assistantId) => {
    navigate(`/assistant/${assistantId}`);
  };

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [assistantsResponse, categoriesResponse] = await Promise.all([
          api.get('/public_assistants_with_stats'),
          api.get('/asistentes/categories'),
        ]);

        if (assistantsResponse.status === 200) {
          setAssistants(assistantsResponse.data.assistants);
          setFilteredAssistants(assistantsResponse.data.assistants);
          // Establecer rangos máximos basados en los datos
          const maxFavorites = Math.max(...assistantsResponse.data.assistants.map(a => a.num_favorites), 500);
          const maxLikes = Math.max(...assistantsResponse.data.assistants.map(a => a.num_likes), 100);
          const maxConversations = Math.max(...assistantsResponse.data.assistants.map(a => a.num_conversations), 1000);
          setFavoritesRange([0, maxFavorites]);
          setLikesRange([0, maxLikes]);
          setConversationsRange([0, maxConversations]);
        }

        if (categoriesResponse.status === 200) {
          setCategories(categoriesResponse.data.categories);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
        toast.error('Error al obtener los datos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Debounced Search Handler
  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      setCurrentPage(1); // Reiniciar a la primera página al buscar
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value);
  };

  // Handle Sort Change
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Handle Category Change
  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue === '' ? '' : Number(selectedValue));
    setCurrentPage(1); // Reiniciar a la primera página al filtrar
  };

  // Handle Favorites Range Change
  const handleFavoritesChange = (event, newValue) => {
    setFavoritesRange(newValue);
    setCurrentPage(1);
  };

  // Handle Likes Range Change
  const handleLikesChange = (event, newValue) => {
    setLikesRange(newValue);
    setCurrentPage(1);
  };

  // Handle Conversations Range Change
  const handleConversationsChange = (event, newValue) => {
    setConversationsRange(newValue);
    setCurrentPage(1);
  };

  // Filtrar y ordenar asistentes
  useEffect(() => {
    let updatedAssistants = [...assistants];

    // Filtro de búsqueda por nombre
    if (searchQuery.trim() !== '') {
      updatedAssistants = updatedAssistants.filter((assistant) =>
        assistant.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Filtro por categoría
    if (selectedCategory !== '') {
      updatedAssistants = updatedAssistants.filter(
        (assistant) => assistant.TypeID === selectedCategory
      );
    }

    // Filtro por rango de favoritos
    updatedAssistants = updatedAssistants.filter(
      (assistant) =>
        assistant.num_favorites >= favoritesRange[0] &&
        (favoritesRange[1] === Math.max(...assistants.map(a => a.num_favorites), 500) || assistant.num_favorites <= favoritesRange[1])
    );

    // Filtro por rango de likes
    updatedAssistants = updatedAssistants.filter(
      (assistant) =>
        assistant.num_likes >= likesRange[0] &&
        (likesRange[1] === Math.max(...assistants.map(a => a.num_likes), 100) || assistant.num_likes <= likesRange[1])
    );

    // Filtro por rango de conversaciones
    updatedAssistants = updatedAssistants.filter(
      (assistant) =>
        assistant.num_conversations >= conversationsRange[0] &&
        (conversationsRange[1] === Math.max(...assistants.map(a => a.num_conversations), 1000) || assistant.num_conversations <= conversationsRange[1])
    );

    // Ordenamiento
    switch (sortOption) {
      case 'date-newest':
        updatedAssistants.sort(
          (a, b) => new Date(b.CreationDate) - new Date(a.CreationDate)
        );
        break;
      case 'date-oldest':
        updatedAssistants.sort(
          (a, b) => new Date(a.CreationDate) - new Date(b.CreationDate)
        );
        break;
      case 'popularity-desc':
        updatedAssistants.sort(
          (a, b) =>
            b.num_favorites + b.num_likes + b.num_conversations -
            (a.num_favorites + a.num_likes + a.num_conversations)
        );
        break;
      case 'favorites-desc':
        updatedAssistants.sort(
          (a, b) => b.num_favorites - a.num_favorites
        );
        break;
      case 'likes-desc':
        updatedAssistants.sort(
          (a, b) => b.num_likes - a.num_likes
        );
        break;
      case 'conversations-desc':
        updatedAssistants.sort(
          (a, b) => b.num_conversations - a.num_conversations
        );
        break;
      default:
        // Sin orden específico
        break;
    }

    setFilteredAssistants(updatedAssistants);
  }, [
    assistants,
    sortOption,
    searchQuery,
    selectedCategory,
    favoritesRange,
    likesRange,
    conversationsRange,
  ]);

  // Obtener asistentes para la página actual
  const indexOfLastAssistant = currentPage * assistantsPerPage;
  const indexOfFirstAssistant = indexOfLastAssistant - assistantsPerPage;
  const currentAssistants = filteredAssistants.slice(indexOfFirstAssistant, indexOfLastAssistant);
  const totalPages = Math.ceil(filteredAssistants.length / assistantsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <Box className="loading-container" role="status" aria-live="polite">
        <CircularProgress aria-label="Cargando asistentes" />
      </Box>
    );
  }

  return (
    <Box className="main-wrapper">
      {/* Sidebar */}
      <SidebarCreateAsistente />

      {/* Main Content */}
      <Box component="main" className="main-content">
        <Container maxWidth="lg">
          {/* Header Container */}
          <Box className="header-container">
            <Typography variant="h3" gutterBottom className="title">
              Descubrir Asistentes
            </Typography>
          </Box>

          {/* Filtros de Ordenamiento, Búsqueda, Categoría y Rangos */}
          <Box className="filters-container">
            <Grid container spacing={3}>
              {/* Barra de Búsqueda */}
              <Grid item xs={12} md={4}>
                <TextField
                  label="Buscar Asistente"
                  variant="outlined"
                  onChange={handleSearchChange}
                  fullWidth
                  className="search-bar"
                  aria-label="Buscar Asistente"
                  InputProps={{
                    startAdornment: <PublicIcon />,
                  }}
                />
              </Grid>

              {/* Ordenamiento */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl variant="outlined" className="sort-control" fullWidth>
                  <InputLabel id="sort-label">Ordenar Por</InputLabel>
                  <Select
                    labelId="sort-label"
                    value={sortOption}
                    onChange={handleSortChange}
                    label="Ordenar Por"
                    aria-label="Ordenar Por"
                  >
                    <MenuItem value="date-newest">Fecha de Creación (Más Reciente)</MenuItem>
                    <MenuItem value="date-oldest">Fecha de Creación (Más Antiguo)</MenuItem>
                    <MenuItem value="popularity-desc">Popularidad (Más)</MenuItem>
                    <MenuItem value="favorites-desc">Favoritos (Más)</MenuItem>
                    <MenuItem value="likes-desc">Likes (Más)</MenuItem>
                    <MenuItem value="conversations-desc">Conversaciones (Más)</MenuItem>
                    <MenuItem value="">
                      <em>Sin Orden</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Filtro por Categoría */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl variant="outlined" className="category-filter" fullWidth>
                  <InputLabel id="category-label">Categoría</InputLabel>
                  <Select
                    labelId="category-label"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Categoría"
                    aria-label="Filtrar por Categoría"
                  >
                    <MenuItem value="">
                      <em>Todas las Categorías</em>
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.TypeID} value={category.TypeID}>
                        {category.TypeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Filtro por Número de Favoritos */}
              <Grid item xs={12} md={4}>
                <Typography id="favorites-slider" gutterBottom>
                  Número de Favoritos
                </Typography>
                <Stack spacing={2}>
                  <Slider
                    value={favoritesRange}
                    onChange={handleFavoritesChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={Math.max(...assistants.map(a => a.num_favorites), 500)}
                    aria-labelledby="favorites-slider"
                    className="slider-grey"
                  />
                  <Typography variant="caption" className="slider-label">
                    {favoritesRange[1] === Math.max(...assistants.map(a => a.num_favorites), 500) ? `+${favoritesRange[1]}` : favoritesRange[1]}
                  </Typography>
                </Stack>
              </Grid>

              {/* Filtro por Número de Likes */}
              <Grid item xs={12} md={4}>
                <Typography id="likes-slider" gutterBottom>
                  Número de Likes
                </Typography>
                <Stack spacing={2}>
                  <Slider
                    value={likesRange}
                    onChange={handleLikesChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={Math.max(...assistants.map(a => a.num_likes), 100)}
                    aria-labelledby="likes-slider"
                    className="slider-grey"
                  />
                  <Typography variant="caption" className="slider-label">
                    {likesRange[1] === Math.max(...assistants.map(a => a.num_likes), 100) ? `+${likesRange[1]}` : likesRange[1]}
                  </Typography>
                </Stack>
              </Grid>

              {/* Filtro por Número de Conversaciones */}
              <Grid item xs={12} md={4}>
                <Typography id="conversations-slider" gutterBottom>
                  Número de Conversaciones
                </Typography>
                <Stack spacing={2}>
                  <Slider
                    value={conversationsRange}
                    onChange={handleConversationsChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={Math.max(...assistants.map(a => a.num_conversations), 1000)}
                    aria-labelledby="conversations-slider"
                    className="slider-grey"
                  />
                  <Typography variant="caption" className="slider-label">
                    {conversationsRange[1] === Math.max(...assistants.map(a => a.num_conversations), 1000) ? `+${conversationsRange[1]}` : conversationsRange[1]}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          {/* Lista de Asistentes */}
          <Grid container spacing={4}>
            {currentAssistants.length > 0 ? (
              currentAssistants.map((assistant) => (
                <AsistenteCard
                  key={assistant.AssistantID}
                  assistant={assistant}
                  handleChat={handleChat}
                />
              ))
            ) : (
              <Box className="no-results">
                <Typography variant="h6" color="text.secondary">
                  No se encontraron asistentes que coincidan con tu búsqueda.
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Paginación */}
          {totalPages > 1 && (
            <Box className="pagination-container">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
                aria-label="Paginación de Asistentes"
              />
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Discovery;
