// src/components/Admin/GestionUsuarios.js
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@mui/material';
import api from '../../api';
import { toast } from 'react-toastify';

const GestionUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUsuarios = async () => {
    try {
      const response = await api.get('/admin/usuarios'); // Asegúrate de tener esta ruta en tu backend
      setUsuarios(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener usuarios:', error);
      toast.error('Error al obtener usuarios.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const handleEliminar = async (id) => {
    if (window.confirm('¿Estás seguro de eliminar este usuario?')) {
      try {
        await api.delete(`/admin/usuarios/${id}`);
        toast.success('Usuario eliminado correctamente.');
        fetchUsuarios();
      } catch (error) {
        console.error('Error al eliminar usuario:', error);
        toast.error('Error al eliminar usuario.');
      }
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Gestión de Usuarios
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="tabla de usuarios">
            <TableHead>
              <TableRow>
                <TableCell>Nombre de Usuario</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((usuario) => (
                <TableRow key={usuario.id}>
                  <TableCell>{usuario.username}</TableCell>
                  <TableCell>{usuario.email}</TableCell>
                  <TableCell>{usuario.role}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleEliminar(usuario.id)}
                    >
                      Eliminar
                    </Button>
                    {/* Puedes agregar más acciones como editar */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default GestionUsuarios;
