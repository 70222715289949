// src/components/RightSidebar.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import {
  ThumbUp as ThumbUpIcon,
  Favorite as FavoriteIcon,
  Share as ShareIcon, // Importar el ícono de Compartir
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import api from '../api'; // Asegúrate de importar la instancia de Axios
import './RightSidebar.css'; // Crea este archivo si necesitas estilos adicionales específicos para RightSidebar

const RightSidebar = ({ assistantName, assistantImageUrl, assistantDescription, isMobile, assistantId }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);

  // Obtener el estado inicial de "Favorito" y "Me gusta" al montar el componente
  useEffect(() => {
    const fetchFavoriteStatus = async () => {
      try {
        const response = await api.get(`/assistants/${assistantId}/favorite-status`);
        if (response.status === 200) {
          setIsFavorited(response.data.isFavorited);
        }
      } catch (error) {
        console.error('Error al verificar si es favorito:', error);
        toast.error('Error al obtener favoritos');
      }
    };

    const fetchLikeStatus = async () => {
      try {
        const response = await api.get(`/assistants/${assistantId}/like-status`);
        if (response.status === 200) {
          setIsLiked(response.data.isLiked);
        }
      } catch (error) {
        console.error('Error al verificar si es "Me gusta":', error);
        toast.error('Error al obtener "Me gusta"');
      }
    };

    fetchFavoriteStatus();
    fetchLikeStatus();
  }, [assistantId]);

  const handleLike = async () => {
    try {
      const action = isLiked ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/like`, { action });
      if (response.status === 200) {
        setIsLiked(!isLiked);
        if (!isLiked) {
          toast.success('¡Gracias por tu apoyo!', {
            position: 'top-center',
            autoClose: 2000,
          });
        } else {
          toast.info('Me gusta eliminado', {
            position: 'top-center',
            autoClose: 2000,
          });
        }
      } else {
        toast.error('Error al actualizar "Me gusta"');
      }
    } catch (error) {
      console.error('Error al actualizar "Me gusta":', error);
      toast.error('Error al actualizar "Me gusta"');
    }
  };

  const handleFavorite = async () => {
    try {
      const action = isFavorited ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/favorite`, { action });
      if (response.status === 200) {
        setIsFavorited(!isFavorited);
        if (!isFavorited) {
          toast.info('Asistente guardado como favorito', {
            position: 'top-center',
            autoClose: 2000,
          });
        } else {
          toast.info('Asistente eliminado de favoritos', {
            position: 'top-center',
            autoClose: 2000,
          });
        }
      } else {
        toast.error('Error al actualizar favorito');
      }
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
      toast.error('Error al actualizar favorito');
    }
  };

  // Nueva función para manejar la compartición
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        toast.success('URL copiada');
      })
      .catch((err) => {
        console.error('Error al copiar la URL:', err);
        toast.error('Error al copiar la URL');
      });
  };

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '300px',
        height: '100%',
        backgroundColor: '#F7F9FC',
        padding: '30px 15px',
        position: isMobile ? 'relative' : 'fixed',
        right: 0,
        top: 0,
        boxSizing: 'border-box',
        overflowY: 'auto',
      }}
    >
      {/* Imagen de Perfil del Asistente con Olas Animadas */}
      <Box className="avatar-waves">
        <Avatar
          src={assistantImageUrl}
          sx={{
            width: '60px',
            height: '60px',
            border: '2px solid #CCCCCC',
            margin: '0 auto 15px auto',
          }}
        />
      </Box>

      {/* Nombre del Asistente */}
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Arial',
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#333333',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        {assistantName}
      </Typography>

      {/* Descripción del Asistente */}
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Arial',
          fontSize: '16px',
          color: '#666666',
          paddingLeft: '10px',
          paddingRight: '10px',
          lineHeight: 1.6,
          marginBottom: '25px',
          textAlign: 'center',
        }}
      >
        {assistantDescription} {/* Utilizar la descripción dinámica */}
      </Typography>

      {/* Botones de Me gusta, Guardar como Favorito y Compartir */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
        {/* Botón "Me gusta" */}
        <Button
          variant="outlined"
          startIcon={<ThumbUpIcon />}
          onClick={handleLike}
          sx={{
            width: '80%',
            height: '45px',
            backgroundColor: isLiked ? '#D0E8FF' : '#E5E7EB',
            borderRadius: '25px',
            color: '#333333',
            fontFamily: 'Arial',
            fontSize: '16px',
            textTransform: 'none',
            justifyContent: 'center',
            paddingLeft: '20px',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.05)',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: isLiked ? '#B0D8FF' : '#d1d5db',
            },
          }}
        >
          Me gusta
        </Button>

        {/* Botón "Guardar como Favorito" */}
        <Button
          variant="outlined"
          startIcon={<FavoriteIcon />}
          onClick={handleFavorite}
          sx={{
            width: '80%',
            height: '45px',
            backgroundColor: isFavorited ? '#FFD1DC' : '#E5E7EB',
            borderRadius: '25px',
            color: '#333333',
            fontFamily: 'Arial',
            fontSize: '16px',
            textTransform: 'none',
            justifyContent: 'center',
            paddingLeft: '20px',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.05)',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: isFavorited ? '#FFC1CC' : '#d1d5db',
            },
          }}
        >
          {isFavorited ? 'Favorito' : 'Guardar como Favorito'}
        </Button>

        {/* Nuevo Botón "Compartir" */}
        <Button
          variant="outlined"
          startIcon={<ShareIcon />}
          onClick={handleShare}
          sx={{
            width: '80%',
            height: '45px',
            backgroundColor: '#E5E7EB',
            borderRadius: '25px',
            color: '#333333',
            fontFamily: 'Arial',
            fontSize: '16px',
            textTransform: 'none',
            justifyContent: 'center',
            paddingLeft: '20px',
            boxShadow: '0px 2px 5px rgba(0,0,0,0.05)',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#d1d5db',
            },
          }}
        >
          Compartir
        </Button>
      </Box>
    </Box>
  );
};

export default RightSidebar;
