// src/components/CookieConsent.js

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookie_consent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookie_consent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookie_consent', 'rejected');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#f4f4f4',
        borderRadius: '15px',
        padding: '20px',
        zIndex: 1000,
        maxWidth: '300px',
      }}
    >
      <Typography variant="body2" sx={{ marginBottom: '10px' }}>
        Usamos cookies para mejorar tu experiencia. Consulta nuestra{' '}
        <a href="/privacy">Política de Privacidad</a>.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          Aceptar todo
        </Button>
        <Button variant="outlined" onClick={handleReject}>
          Rechazar todo
        </Button>
      </Box>
    </Box>
  );
};

export default CookieConsent;
