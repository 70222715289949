// src/components/Admin/GestionAsistentes.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from '@mui/material';
import { Search as SearchIcon, Edit as EditIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { toast } from 'react-toastify';

const GestionAsistentes = () => {
  const [asistentes, setAsistentes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAsistentes, setFilteredAsistentes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const asistentesPerPage = 10; // Número de asistentes por página
  const navigate = useNavigate();

  useEffect(() => {
    fetchAsistentes();
  }, []);

  const fetchAsistentes = async () => {
    try {
      const response = await api.get('/admin/asistentes');
      setAsistentes(response.data.assistants);
      setFilteredAsistentes(response.data.assistants);
    } catch (error) {
      console.error('Error al obtener asistentes:', error);
      toast.error('Error al obtener asistentes');
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === '') {
      setFilteredAsistentes(asistentes);
    } else {
      const filtered = asistentes.filter(
        (asistente) =>
          asistente.Name.toLowerCase().includes(term.toLowerCase()) ||
          (asistente.Description &&
            asistente.Description.toLowerCase().includes(term.toLowerCase()))
      );
      setFilteredAsistentes(filtered);
    }
  };

  const handleEdit = (assistantId) => {
    // Navegar a la pantalla de edición del asistente
    navigate(`/admin/editar-asistente/${assistantId}`);
  };

  const indexOfLastAssistant = currentPage * asistentesPerPage;
  const indexOfFirstAssistant = indexOfLastAssistant - asistentesPerPage;
  const currentAsistentes = filteredAsistentes.slice(
    indexOfFirstAssistant,
    indexOfLastAssistant
  );

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      {/* Encabezado */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4">Gestión de Asistentes</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/admin/crear-asistentes')}
        >
          Crear Nuevo Asistente
        </Button>
      </Box>

      {/* Barra de búsqueda */}
      <Box mb={2}>
        <TextField
          label="Buscar por Nombre/Descripción"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="buscar">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Tabla de asistentes */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre del Asistente</TableCell>
              <TableCell># Conversaciones</TableCell>
              <TableCell>Última Conversación</TableCell>
              <TableCell>Fecha de Creación</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentAsistentes.map((asistente) => (
              <TableRow key={asistente.AssistantID}>
                <TableCell>{asistente.Name}</TableCell>
                <TableCell>{asistente.TotalConversations || 0}</TableCell>
                <TableCell>{asistente.LastConversationDate || 'N/A'}</TableCell>
                <TableCell>{asistente.CreationDate || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(asistente.AssistantID)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(filteredAsistentes.length / asistentesPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default GestionAsistentes;
