// src/components/TermsAndConditions.js

import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" paragraph>
        Last Updated: 05/04/2024
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to FaceMyAI, S.L. ("FaceMyAI," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your access to and use of our website, products, and services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our Services.
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">1. Acceptance of Terms</Typography>
        <Typography variant="body1" paragraph>
          By creating an account, accessing, or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and our <Link href="/privacy-policy">Privacy Policy</Link>, which is incorporated herein by reference.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">2. Eligibility</Typography>
        <Typography variant="body1" paragraph>
          To use our Services, you must be at least 18 years old or the legal age of majority in your jurisdiction. By using our Services, you represent and warrant that you meet this requirement.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">3. Account Registration</Typography>
        <Typography variant="body1" paragraph>
          <strong>• Accuracy of Information:</strong> You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate and complete.<br />
          <strong>• Account Security:</strong> You are responsible for safeguarding your account credentials and for any activities or actions under your account. Notify us immediately of any unauthorized use of your account.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">4. Use of Services</Typography>
        <Typography variant="body1" paragraph>
          <strong>• Permitted Use:</strong> You may use the Services for personal or business purposes in accordance with these Terms.<br />
          <strong>• Prohibited Activities:</strong> You agree not to:
        </Typography>
        <ul>
          <li>Use the Services for any unlawful or prohibited purpose.</li>
          <li>Upload or transmit any harmful code or content.</li>
          <li>Interfere with or disrupt the integrity or performance of the Services.</li>
          <li>Attempt to gain unauthorized access to any part of the Services.</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">5. User-Generated Content</Typography>
        <Typography variant="body1" paragraph>
          <strong>• Ownership:</strong> You retain ownership of any content you submit, post, or display on or through the Services ("User Content").<br />
          <strong>• License:</strong> By submitting User Content, you grant FaceMyAI a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display such content for the purpose of providing and improving the Services.<br />
          <strong>• Responsibility:</strong> You are solely responsible for your User Content. You represent that you have all necessary rights to grant us the above license.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">6. Intellectual Property Rights</Typography>
        <Typography variant="body1" paragraph>
          <strong>• FaceMyAI Property:</strong> All materials and content provided by FaceMyAI, including but not limited to software, designs, text, graphics, and logos, are owned by FaceMyAI or its licensors and are protected by intellectual property laws.<br />
          <strong>• Restrictions:</strong> You may not copy, modify, distribute, sell, or lease any part of our Services or included software without our prior written consent.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">7. Fees and Payment</Typography>
        <Typography variant="body1" paragraph>
          <strong>• Pricing:</strong> Access to certain features of the Services may require payment of fees. All fees are in [Currency] and are non-refundable unless otherwise stated.<br />
          <strong>• Payment Terms:</strong> You agree to pay all applicable fees as described on the website in connection with the Services you select. We reserve the right to change our prices at any time.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">8. Third-Party Services</Typography>
        <Typography variant="body1" paragraph>
          Our Services may contain links to third-party websites or services that are not owned or controlled by FaceMyAI. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">9. Termination</Typography>
        <Typography variant="body1" paragraph>
          <strong>• By You:</strong> You may terminate your account at any time by following the instructions on the website.<br />
          <strong>• By Us:</strong> We may suspend or terminate your access to the Services at our sole discretion, without prior notice or liability, for any reason, including if you breach these Terms.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">10. Disclaimers</Typography>
        <Typography variant="body1" paragraph>
          <strong>• As-Is Basis:</strong> The Services are provided on an "as is" and "as available" basis without warranties of any kind.<br />
          <strong>• No Warranty:</strong> FaceMyAI disclaims all warranties, express or implied, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">11. Limitation of Liability</Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, in no event shall FaceMyAI, its affiliates, directors, employees, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or other intangible losses arising from your use of the Services.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">12. Indemnification</Typography>
        <Typography variant="body1" paragraph>
          You agree to defend, indemnify, and hold harmless FaceMyAI and its affiliates from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">13. Governing Law and Jurisdiction</Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed by and construed in accordance with the laws of Spain, without regard to its conflict of law provisions. Any disputes arising out of or relating to these Terms shall be resolved in the courts of Zaragoza, Spain.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">14. Changes to the Terms</Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least [30 days'] notice prior to any new terms taking effect. Your continued use of the Services after the effective date constitutes acceptance of the new Terms.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">15. Miscellaneous</Typography>
        <Typography variant="body1" paragraph>
          <strong>• Entire Agreement:</strong> These Terms constitute the entire agreement between you and FaceMyAI regarding the Services.<br />
          <strong>• Severability:</strong> If any provision of these Terms is held invalid, the remainder shall continue in full force and effect.<br />
          <strong>• Waiver:</strong> Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">16. Contact Information</Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at:
        </Typography>
        <ul>
          <li><strong>Email:</strong> <Link href="mailto:facemyai@facemyai.com">facemyai@facemyai.com</Link></li>
          <li><strong>Telephone:</strong> +34 655359830</li>
          <li><strong>Address:</strong> calle maria moliner numero 69, escalera izquierda 8º Derecha, Zaragoza, España</li>
        </ul>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph>
          ________________________________________
        </Typography>
        <Typography variant="body1" paragraph>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
