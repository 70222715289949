// frontend/src/components/ProfileSettingsPopup.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Avatar,
  CircularProgress,
  Typography,
  Tabs,
  Tab,
  Box,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Grid,
  useTheme,
  useMediaQuery,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material';
import { PhotoCamera, Person, AccountCircle, Settings as SettingsIcon } from '@mui/icons-material';
import api from '../api'; // Asegúrate de importar la instancia 'api'
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { styled, keyframes } from '@mui/system';

// Animaciones
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const glow = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(76, 175, 80, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(51, 51, 51, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
  }
`;

// Componentes de pestañas personalizados
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            pt: 2,
            animation: `${fadeIn} 0.5s ease-in-out`,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Personalización de los Tabs para que tengan el mismo tamaño y un aspecto moderno
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  flexGrow: 1,
  textTransform: 'none',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  borderRadius: '15px',
  transition: 'background-color 0.3s, color 0.3s',
  color: theme.palette.text.primary,
  fontFamily: 'Montserrat, sans-serif',
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

// Botón con efecto de resplandor
const GlowButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  // Efecto de resplandor cuando está activo
  '&.glow': {
    animation: `${glow} 2s infinite`,
  },
}));

// Botón con efecto de pulsación
const PulseButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '15px',
  backgroundColor: '#333333', // Negro para fondo
  color: '#FFFFFF', // Blanco para texto
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.2s',
  animation: `${pulse} 3s infinite`,
  '&:hover': {
    backgroundColor: '#4F4F4F',
    transform: 'scale(1.05)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

const ProfileSettingsPopup = ({ open, handleClose, userInfo, refreshUserInfo }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [tabValue, setTabValue] = useState(0);

  // Estado para Perfil Público
  const [username, setUsername] = useState(userInfo.UserName || '');
  const [email, setEmail] = useState(userInfo.Email || '');
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(userInfo.ProfileImage || '');
  const [errors, setErrors] = useState({});

  // Estado para Cuenta
  const [currentPlan, setCurrentPlan] = useState('Gratis'); // Se actualizará basado en UserTypeID
  const [isPlanHighest, setIsPlanHighest] = useState(false); // Determinar si el plan es el más alto disponible

  // Estado para Preferencias
  const [notifications, setNotifications] = useState(userInfo.EmailNotifications || false); // Obtener desde userInfo
  const [language, setLanguage] = useState(userInfo.Language || 'en'); // Idioma por defecto desde userInfo

  const [loading, setLoading] = useState(false);

  // Estado para Snackbar de éxito
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Mapeo de UserTypeID a nombre de plan
  const getPlanName = (userTypeId) => {
    switch (userTypeId) {
      case 1:
        return 'Gratis';
      case 2:
        return 'Premium';
      case 3:
        return 'Admin';
      default:
        return 'Desconocido';
    }
  };

  // Determinar si el plan es el más alto (suponiendo que Premium es el más alto para usuarios estándar)
  const determineIsPlanHighest = (userTypeId) => {
    // Admin es un rol especial, no debe ser actualizable por el usuario
    if (userTypeId === 3) return true;
    // Suponiendo que Premium es el plan más alto para usuarios estándar
    return userTypeId === 2;
  };

  useEffect(() => {
    setUsername(userInfo.UserName || '');
    setEmail(userInfo.Email || '');
    setPreviewImage(userInfo.ProfileImage || '');
    setProfileImage(null);
    setErrors({});
    // Actualizar el plan basado en UserTypeID
    const planName = getPlanName(userInfo.UserTypeID || 1); // Default to 'Gratis' si undefined
    setCurrentPlan(planName);
    const highest = determineIsPlanHighest(userInfo.UserTypeID || 1);
    setIsPlanHighest(highest);
    setNotifications(userInfo.EmailNotifications || false); // Obtener desde userInfo
    setLanguage(userInfo.Language || 'en'); // Obtener desde userInfo
  }, [userInfo, open]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validate = () => {
    const newErrors = {};

    // Validaciones de Nombre de Usuario
    if (!username.trim()) {
      newErrors.username = 'El nombre de usuario es obligatorio';
    } else if (username.length > 20) {
      newErrors.username = 'El nombre de usuario no puede exceder los 20 caracteres';
    } else if (/^\s|\s$/.test(username)) {
      newErrors.username = 'No se permiten espacios al inicio o al final';
    }

    // Validaciones de Correo Electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      newErrors.email = 'El correo electrónico es obligatorio';
    } else if (!emailRegex.test(email)) {
      newErrors.email = 'El formato del correo electrónico no es válido';
    }

    // Validaciones de Imagen de Perfil
    if (profileImage) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!allowedTypes.includes(profileImage.type)) {
        newErrors.profileImage = 'Solo se permiten formatos .jpg, .png, .jpeg';
      }
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (profileImage.size > maxSize) {
        newErrors.profileImage = 'El tamaño de la imagen no debe exceder los 2MB';
      }
    }

    // Validaciones de Preferencias de Idioma
    const allowedLanguages = ['es', 'en', 'fr', 'de', 'it', 'zh', 'ar'];
    if (language && !allowedLanguages.includes(language)) {
      newErrors.language = 'Idioma seleccionado no es válido';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('username', username.trim());
    formData.append('email', email.trim());
    formData.append('EmailNotifications', notifications); // Añadido
    formData.append('Language', language); // Añadido
    if (profileImage) {
      formData.append('profileImage', profileImage); // Asegúrate de que el backend espera 'profileImage'
    }

    try {
      const response = await api.put('/user/update-profile', formData); // Elimina el 'headers'

      if (response.status === 200) {
        toast.success('Perfil actualizado correctamente');
        setOpenSnackbar(true);
        refreshUserInfo();
        handleClose();
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error); // Log en consola

      if (error.response && error.response.data && error.response.data.error) {
        const errorData = error.response.data.error;
        if (typeof errorData === 'object') {
          // Mostrar errores específicos
          Object.values(errorData).forEach((msg) => toast.error(msg));
        } else {
          toast.error(errorData);
        }
      } else {
        toast.error('No se pudo actualizar el perfil, intenta nuevamente');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Opciones de planes disponibles
  const availablePlans = [
    { id: 1, name: 'Gratis' },
    { id: 2, name: 'Premium' },
    // { id: 3, name: 'Admin' }, // Admin no es un plan actualizable por el usuario
  ];

  // Manejar la actualización del plan
  const handlePlanUpdate = async () => {
    // Aquí deberías implementar la lógica para actualizar el plan del usuario
    // Por ejemplo, redirigir a una página de pago o mostrar un modal con opciones
    toast.info('Funcionalidad de actualización de plan no implementada aún.');
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          animation: `${fadeIn} 0.5s ease-in-out`,
          backgroundColor: '#F0F0F0', // Gris Claro
          color: '#333333', // Negro Suave
          fontFamily: 'Montserrat, sans-serif',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: '600',
          fontSize: '1.8rem',
          mb: 2,
          color: '#333333',
        }}
      >
        Configuración
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {/* Columna Izquierda: Navegación de Pestañas */}
          <Grid item xs={12} md={3}>
            <StyledTabs
              orientation="vertical"
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Configuración de pestañas"
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexDirection: 'column',
                },
              }}
            >
              <Tooltip title="Editar tu información personal" placement="right">
                <StyledTab
                  label="Perfil Público"
                  icon={<Person sx={{ color: '#333333' }} />}
                  iconPosition="start"
                  id="settings-tab-0"
                  aria-controls="settings-tabpanel-0"
                />
              </Tooltip>
              <Tooltip title="Gestiona tu cuenta y suscripciones" placement="right">
                <StyledTab
                  label="Cuenta"
                  icon={<AccountCircle sx={{ color: '#333333' }} />}
                  iconPosition="start"
                  id="settings-tab-1"
                  aria-controls="settings-tabpanel-1"
                />
              </Tooltip>
              <Tooltip title="Personaliza tu experiencia" placement="right">
                <StyledTab
                  label="Preferencias"
                  icon={<SettingsIcon sx={{ color: '#333333' }} />}
                  iconPosition="start"
                  id="settings-tab-2"
                  aria-controls="settings-tabpanel-2"
                />
              </Tooltip>
            </StyledTabs>
          </Grid>

          {/* Columna Derecha: Contenido de las Pestañas */}
          <Grid item xs={12} md={9}>
            <TabPanel value={tabValue} index={0}>
              {/* Perfil Público */}
              <Box display="flex" flexDirection="column" gap={3}>
                <TextField
                  label="Nombre de Usuario"
                  type="text"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  error={!!errors.username}
                  helperText={errors.username || `${username.length}/20`}
                  inputProps={{ maxLength: 20 }}
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: '#D9D9D9' },
                      '&:hover fieldset': { borderColor: '#333333' },
                      '&.Mui-focused fieldset': { borderColor: '#333333' },
                      color: '#333333',
                    },
                    '& .MuiFormHelperText-root': { color: '#333333' },
                  }}
                  InputProps={{
                    endAdornment: !errors.username && username && (
                      <Typography variant="body2" color="#4CAF50">
                        ✓
                      </Typography>
                    ),
                  }}
                />
                <TextField
                  label="Correo Electrónico"
                  type="email"
                  fullWidth
                  value={email}
                  // Eliminamos el onChange ya que el campo no es editable
                  error={!!errors.email}
                  helperText={errors.email}
                  variant="outlined"
                  InputProps={{
                    readOnly: true, // Hacer el campo no editable
                    endAdornment: !errors.email && email && (
                      <Typography variant="body2" color="#4CAF50">
                        ✓
                      </Typography>
                    ),
                  }}
                  sx={{
                    '& .MuiInputLabel-root': { color: '#333333' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: '#D9D9D9' },
                      '&:hover fieldset': { borderColor: '#333333' },
                      '&.Mui-focused fieldset': { borderColor: '#333333' },
                      color: '#333333',
                      backgroundColor: '#E0E0E0', // Fondo más oscuro para indicar no editable
                    },
                    '& .MuiFormHelperText-root': { color: '#F44336' },
                  }}
                />
                <Box display="flex" alignItems="center" gap={3}>
                  <Avatar
                    src={previewImage}
                    sx={{
                      width: 120,
                      height: 120,
                      borderRadius: '15px',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  <Box>
                    <label htmlFor="profile-image-upload">
                      <input
                        accept="image/*"
                        id="profile-image-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<PhotoCamera />}
                        sx={{
                          textTransform: 'none',
                          borderRadius: '15px',
                          backgroundColor: '#D9D9D9', // Gris Claro para fondo
                          color: '#333333', // Negro para texto
                          '&:hover': {
                            backgroundColor: '#B0B0B0',
                          },
                        }}
                      >
                        Cambiar Imagen
                      </Button>
                    </label>
                    {profileImage && (
                      <Typography variant="body2" color="#333333" mt={1}>
                        {profileImage.name}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {errors.profileImage && (
                  <Typography color="#F44336" variant="body2">
                    {errors.profileImage}
                  </Typography>
                )}
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {/* Cuenta */}
              <Box display="flex" flexDirection="column" gap={4}>
                <Box>
                  <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif', color: '#333333' }}>
                    Plan Actual
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid #D9D9D9',
                      borderRadius: '15px',
                      p: 3,
                      backgroundColor: '#FFFFFF',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat, sans-serif', color: '#333333' }}>
                      {currentPlan}
                    </Typography>
                    {!isPlanHighest ? (
                      <GlowButton
                        variant="contained"
                        size="large"
                        className="glow"
                        onClick={handlePlanUpdate}
                        sx={{
                          backgroundColor: '#B0B0B0', // Gris Claro para fondo
                          color: '#000000', // Negro para texto
                          '&:hover': {
                            backgroundColor: '#A0A0A0',
                          },
                        }}
                      >
                        Actualizar Plan
                      </GlowButton>
                    ) : (
                      <Button
                        variant="contained"
                        size="large"
                        disabled
                        sx={{
                          textTransform: 'none',
                          borderRadius: '15px',
                          backgroundColor: '#4F4F4F', // Gris Medio para fondo
                          color: '#FFFFFF', // Blanco para texto
                        }}
                      >
                        Plan Máximo
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              {/* Preferencias */}
              <Box display="flex" flexDirection="column" gap={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={notifications}
                      onChange={(e) => setNotifications(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={<Typography sx={{ color: '#333333' }}>Notificaciones por correo electrónico</Typography>}
                />
                {errors.EmailNotifications && (
                  <Typography color="#F44336" variant="body2">
                    {errors.EmailNotifications}
                  </Typography>
                )}
                <Box>
                  <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif', color: '#333333' }}>
                    Preferencias de Idioma
                  </Typography>
                  <Select
                    fullWidth
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    variant="outlined"
                    sx={{
                      borderRadius: '15px',
                      backgroundColor: '#D9D9D9', // Gris Claro para fondo
                      color: '#333333', // Negro para texto
                      '& .MuiSelect-icon': { color: '#333333' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#333333' },
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#D9D9D9' },
                      '& .MuiSelect-select': { paddingY: '12px' },
                    }}
                  >
                    <MenuItem value="es">Español</MenuItem>
                    <MenuItem value="en">Inglés</MenuItem>
                    <MenuItem value="fr">Francés</MenuItem>
                    <MenuItem value="de">Alemán</MenuItem>
                    <MenuItem value="it">Italiano</MenuItem>
                    <MenuItem value="zh">Chino</MenuItem>
                    <MenuItem value="ar">Árabe</MenuItem>
                    {/* Agrega más idiomas según sea necesario */}
                  </Select>
                  {errors.Language && (
                    <Typography color="#F44336" variant="body2">
                      {errors.Language}
                    </Typography>
                  )}
                </Box>
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, backgroundColor: '#F0F0F0' }}>
        <Button
          onClick={handleCancel}
          disabled={loading}
          color="inherit"
          variant="outlined"
          sx={{
            textTransform: 'none',
            borderRadius: '15px',
            borderColor: '#D9D9D9',
            color: '#333333',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              backgroundColor: '#D9D9D9',
              transform: 'scale(1.02)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          Cancelar
        </Button>
        <PulseButton
          onClick={handleSubmit}
          disabled={loading}
          variant="contained"
          sx={{
            textTransform: 'none',
            borderRadius: '15px',
            backgroundColor: '#333333', // Negro para fondo
            color: '#FFFFFF', // Blanco para texto
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              backgroundColor: '#4F4F4F',
              transform: 'scale(1.05)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : 'Guardar'}
        </PulseButton>
      </DialogActions>

      {/* Snackbar de éxito */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{
            width: '100%',
            borderRadius: '15px',
            backgroundColor: '#4CAF50',
            color: '#FFFFFF',
          }}
        >
          Perfil actualizado correctamente
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

ProfileSettingsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    UserName: PropTypes.string,
    Email: PropTypes.string,
    ProfileImage: PropTypes.string,
    EmailNotifications: PropTypes.bool, // Añadido PropType
    Language: PropTypes.string, // Añadido PropType
    UserTypeID: PropTypes.number, // Añadido PropType para UserTypeID
  }).isRequired,
  refreshUserInfo: PropTypes.func.isRequired,
};

export default ProfileSettingsPopup;
