// src/components/Admin/AdminPanel.js
import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Chat as ChatIcon,
  BarChart as BarChartIcon,
  PersonAdd as PersonAddIcon,
  Settings as SettingsIcon,
  Support as SupportIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import Dashboard from './Dashboard';
import GestionUsuarios from './GestionUsuarios';
import Entrevistas from './Entrevistas';
import Reportes from './Reportes';
import CrearAsistentes from './CrearAsistentes';
import GestionAsistentes from'./GestionAsistentes';
//import Configuracion from './Configuracion';
//import Soporte from './Soporte';
import api from '../../api';
import { toast } from 'react-toastify';

const drawerWidth = 240;

const AdminPanel = ({ isDarkMode, setIsDarkMode }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await api.post('/logout');
      localStorage.removeItem('session_token');
      toast.success('Cierre de sesión exitoso');
      navigate('/');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      toast.error('Error al cerrar sesión');
    }
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: 'dashboard' },
    { text: 'Gestión de Usuarios', icon: <PeopleIcon />, path: 'gestion-usuarios' },
    { text: 'Entrevistas', icon: <ChatIcon />, path: 'entrevistas' },
    { text: 'Reportes', icon: <BarChartIcon />, path: 'reportes' },
    { text: 'Crear Asistentes', icon: <PersonAddIcon />, path: 'crear-asistentes' },
    //{ text: 'Configuración', icon: <SettingsIcon />, path: 'configuracion' },
    //{ text: 'Soporte', icon: <SupportIcon />, path: 'soporte' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Panel de Administración - RRHH
          </Typography>
          {/* Botón para alternar el modo oscuro si es necesario */}
          {/* Puedes agregar aquí el toggle de modo oscuro */}
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer (Navegación Lateral) */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <Routes>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="gestion-usuarios" element={<GestionUsuarios />} />
          <Route path="entrevistas" element={<Entrevistas />} />
          <Route path="reportes" element={<Reportes />} />
          <Route path="crear-asistentes" element={<CrearAsistentes />} />
          <Route path="gestion-asistentes" element={<GestionAsistentes />} />
          { /* //<Route path="configuracion" element={<Configuracion />} 
          <Route path="soporte" element={<Soporte />} />/>*/}
          {/* Ruta por defecto */}
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminPanel;
