// src/components/ResetPasswordPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Alert,
  Grid,
  LinearProgress,
} from '@mui/material';
import { Visibility, VisibilityOff, LockReset } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [token, setToken] = useState('');
  const [formData, setFormData] = useState({
    new_password: '',
    confirm_password: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState('');

  // Extraer el token de la URL cuando el componente se monta
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setFeedback('Token inválido.');
    }
  }, [location.search]);

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'new_password') {
      evaluatePasswordStrength(value);
    }
  };

  // Evaluar la fortaleza de la contraseña
  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;

    setPasswordStrength((strength / 4) * 100);

    switch(strength) {
      case 0:
      case 1:
        setPasswordStrengthLabel('Débil');
        break;
      case 2:
        setPasswordStrengthLabel('Media');
        break;
      case 3:
        setPasswordStrengthLabel('Fuerte');
        break;
      case 4:
        setPasswordStrengthLabel('Muy Fuerte');
        break;
      default:
        setPasswordStrengthLabel('');
    }
  };

  // Manejar mostrar/ocultar contraseña
  const handleClickShowPassword = () => {
    setFormData(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const handleClickShowConfirmPassword = () => {
    setFormData(prevState => ({
      ...prevState,
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFeedback('');

    // Validaciones
    if (formData.new_password !== formData.confirm_password) {
      setFeedback('Las contraseñas no coinciden.');
      toast.error('Las contraseñas no coinciden.');
      return;
    }

    if (formData.new_password.length < 8) {
      setFeedback('La contraseña debe tener al menos 8 caracteres.');
      toast.error('La contraseña debe tener al menos 8 caracteres.');
      return;
    }

    // Puedes añadir más validaciones de complejidad aquí

    setLoading(true);

    try {
      const response = await api.post('/update_password', {
        token: token,
        new_password: formData.new_password,
      });

      toast.success('¡Contraseña restablecida exitosamente! Ahora puedes iniciar sesión.');
      navigate('/login'); 
    } catch (error) {
      if (error.response && error.response.data.error) {
        setFeedback(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setFeedback('Error al restablecer la contraseña.');
        toast.error('Error al restablecer la contraseña.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: { xs: 4, sm: 8 }, mb: { xs: 4, sm: 8 } }}>
      <Card elevation={6}>
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <LockReset color="primary" sx={{ fontSize: 60 }} />
              <Typography variant="h5" gutterBottom>
                Restablecer Contraseña
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Introduce tu nueva contraseña para continuar.
              </Typography>
            </Grid>

            {feedback && (
              <Grid item xs={12}>
                <Alert severity="error" variant="filled">
                  {feedback}
                </Alert>
              </Grid>
            )}

            {token ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Nueva Contraseña"
                    type={formData.showPassword ? 'text' : 'password'}
                    name="new_password"
                    value={formData.new_password}
                    onChange={handleChange}
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <LinearProgress 
                    variant="determinate" 
                    value={passwordStrength} 
                    color={
                      passwordStrength < 50 
                        ? 'error' 
                        : passwordStrength < 75 
                          ? 'warning' 
                          : 'success'
                    }
                    sx={{ height: 10, borderRadius: 5 }}
                  />
                  <Typography variant="caption" color={
                    passwordStrength < 50 
                      ? 'error' 
                      : passwordStrength < 75 
                        ? 'warning.main' 
                        : 'success.main'
                  }>
                    {passwordStrengthLabel}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Confirmar Nueva Contraseña"
                    type={formData.showConfirmPassword ? 'text' : 'password'}
                    name="confirm_password"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {formData.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    size="large"
                    sx={{ py: 1.5 }}
                    onClick={handleSubmit}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Actualizar Contraseña'}
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Alert severity="warning" variant="filled">
                  No se ha proporcionado un token válido.
                </Alert>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ResetPasswordPage;
