// src/components/Assistant/Assistant.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import './Assistant.css';
import {
  IconButton,
  Typography,
  CssBaseline,
  Box,
  Snackbar,
  Alert,
  Fade,
  InputAdornment,
  TextField,
  Button,
  AppBar,
  Toolbar,
  Drawer,
} from '@mui/material';
import {
  Mic as MicIcon,
  Send as SendIcon,
  ThumbUp as ThumbUpIcon,
  Favorite as FavoriteIcon,
  Share as ShareIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SidebarCreateAsistente from '../Admin/SidebarCreateAsistente';
import RightSidebar from '../RightSidebar';
import VideoPlayer from './VideoPlayer';

const Assistant = () => {
  // Estados y hooks
  const { assistantId } = useParams();
  const [assistantName, setAssistantName] = useState('');
  const [assistantImageUrl, setAssistantImageUrl] = useState('');
  const [assistantDescription, setAssistantDescription] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isMessageInputDisabled, setIsMessageInputDisabled] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [assistantStatus, setAssistantStatus] = useState('disponible');
  const [errorMessage, setErrorMessage] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [initialPrompts, setInitialPrompts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false); // Nuevo estado

  const navigate = useNavigate();
  const chatEndRef = useRef(null);

  // Efecto para verificar autenticación
  useEffect(() => {
    api
      .get('/check_auth')
      .then(() => {
        // Autenticado
      })
      .catch(() => {
        navigate('/');
      });
  }, [navigate]);

  // Efecto para cargar detalles del asistente
  useEffect(() => {
    const fetchAssistantDetails = async () => {
      try {
        const response = await api.get(`/assistants/${assistantId}`);
        const data = response.data;
        setAssistantName(data.Name);
        setAssistantImageUrl(data.ImageURL);
        setAssistantDescription(data.Description);
        setInitialPrompts(data.InitialPrompt.slice(0, 2));
      } catch (error) {
        console.error('Error al obtener detalles del asistente:', error);
        setErrorMessage('Error al cargar detalles del asistente.');
      }
    };

    fetchAssistantDetails();
  }, [assistantId]);

  // Efecto para obtener el estado de "Me gusta" y "Favorito"
  useEffect(() => {
    const fetchInteractionStatus = async () => {
      try {
        const favoriteResponse = await api.get(`/assistants/${assistantId}/favorite-status`);
        if (favoriteResponse.status === 200) {
          setIsFavorited(favoriteResponse.data.isFavorited);
        }

        const likeResponse = await api.get(`/assistants/${assistantId}/like-status`);
        if (likeResponse.status === 200) {
          setIsLiked(likeResponse.data.isLiked);
        }
      } catch (error) {
        console.error('Error al obtener el estado de interacciones:', error);
      }
    };

    fetchInteractionStatus();
  }, [assistantId]);

  // Efecto para ocultar el mensaje de bienvenida
  useEffect(() => {
    const timer = setTimeout(() => {
      setWelcomeMessage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Detectar tamaño de pantalla para interfaz responsive
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll al final de los mensajes
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, isTyping]);

  const handleRightSidebarToggle = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  const handleLeftSidebarToggle = () => {
    setIsLeftSidebarOpen(!isLeftSidebarOpen);
  };

  // Funciones de envío de mensajes
  const sendMessage = () => {
    const message = messageInput.trim();
    if (message !== '') {
      appendMessage('user', message);
      setMessageInput('');
      setIsMessageInputDisabled(true);
      sendMessageToAssistant(message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const appendMessage = (sender, text) => {
    const newMessage = {
      sender: sender,
      text: text,
      time: new Date().toLocaleTimeString(),
    };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendMessageToAssistant = async (message) => {
    setIsTyping(true);
    setIsMessageInputDisabled(true);
    setIsVideoLoading(true);
    setAssistantStatus('escribiendo');
    try {
      const response = await api.post('/assistant', {
        message,
        assistantId,
      });
      setIsTyping(false);
      setIsMessageInputDisabled(false);
      setAssistantStatus('disponible');
      const data = response.data;
      if (data.error) {
        appendMessage('assistant', 'Error: ' + data.error);
        toast.error('Error al enviar el mensaje.');
        setErrorMessage('Error al enviar el mensaje.');
        setAssistantStatus('disponible');
        setIsVideoLoading(false);
      } else {
        appendMessage('assistant', data.response);
        toast.success('Mensaje recibido.');
        if (data.video_url) {
          setShowVideo(true);
          setVideoUrl(data.video_url);
        } else {
          setShowVideo(false);
          setVideoUrl('');
        }
        setIsVideoLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsTyping(false);
      setIsMessageInputDisabled(false);
      appendMessage('assistant', 'Error al conectarse con el servidor. Por favor, intenta nuevamente.');
      toast.error('Error al conectarse con el servidor.');
      setErrorMessage('Error al conectarse con el servidor.');
      setAssistantStatus('desconectado');
      setIsVideoLoading(false);
    }
  };

  const handleVoiceInput = () => {
    toast.info('Funcionalidad de voz no implementada aún.');
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };

  const handleInitialPromptClick = (prompt) => {
    setInitialPrompts([]);
    appendMessage('user', prompt);
    setIsMessageInputDisabled(true);
    sendMessageToAssistant(prompt);
  };

  // Ocultar iniciadores si el usuario comienza a escribir
  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
    if (initialPrompts.length > 0) {
      setInitialPrompts([]);
    }
  };

  const handleLike = async () => {
    try {
      const action = isLiked ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/like`, { action });
      if (response.status === 200) {
        setIsLiked(!isLiked);
        if (!isLiked) {
          toast.success('¡Gracias por tu apoyo!', {
            position: 'top-center',
            autoClose: 2000,
          });
        } else {
          toast.info('Me gusta eliminado', {
            position: 'top-center',
            autoClose: 2000,
          });
        }
      } else {
        toast.error('Error al actualizar "Me gusta"');
      }
    } catch (error) {
      console.error('Error al actualizar "Me gusta":', error);
      toast.error('Error al actualizar "Me gusta"');
    }
  };

  const handleFavorite = async () => {
    try {
      const action = isFavorited ? 'remove' : 'add';
      const response = await api.post(`/assistants/${assistantId}/favorite`, { action });
      if (response.status === 200) {
        setIsFavorited(!isFavorited);
        if (!isFavorited) {
          toast.info('Asistente guardado como favorito', {
            position: 'top-center',
            autoClose: 2000,
          });
        } else {
          toast.info('Asistente eliminado de favoritos', {
            position: 'top-center',
            autoClose: 2000,
          });
        }
      } else {
        toast.error('Error al actualizar favorito');
      }
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
      toast.error('Error al actualizar favorito');
    }
  };

  // Nueva función para manejar la compartición
  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        toast.success('URL copiada');
      })
      .catch((err) => {
        console.error('Error al copiar la URL:', err);
        toast.error('Error al copiar la URL');
      });
  };

  const handleDrawerClose = () => {
    setIsLeftSidebarOpen(false);
    setIsRightSidebarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />   
      <SidebarCreateAsistente />
      {/* Drawer para el RightSidebar en móvil */}
      <Drawer
        anchor="right"
        open={isRightSidebarOpen}
        onClose={handleRightSidebarToggle}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 280, overflowX: 'hidden' }, // Ancho unificado
        }}
      >
        <Toolbar /> {/* Compensación para el AppBar */}
        <RightSidebar
          assistantName={assistantName}
          assistantImageUrl={assistantImageUrl}
          assistantDescription={assistantDescription}
          isMobile={true}
          assistantId={assistantId}
        />
      </Drawer>

      {/* Contenido Principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1, sm: 2 }, // Reducir padding
          marginLeft: { sm: '60px' },
          marginRight: { sm: '300px' },
          mt: { xs: isMobile ? 7 : 0, sm: 0 }, // Espacio para el AppBar en móvil
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'relative',
        }}
      >
        {/* Mensaje de Error */}
        <Snackbar
          open={errorMessage !== ''}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={Fade}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        {/* Mensaje de Bienvenida */}
        {welcomeMessage && (
          <Box
            sx={{
              backgroundColor: '#f0f4f8',
              padding: '10px', // Reducir padding
              borderRadius: '8px',
              marginBottom: '15px', // Reducir margen
              textAlign: 'center',
              animation: 'fadeIn 1s ease-in-out',
            }}
          >
            <Typography variant="h6" color="#333333" fontSize="1rem">
              ¡Bienvenido de nuevo!
            </Typography>
            <Typography variant="body2" color="#666666" fontSize="0.875rem">
              ¿En qué puedo ayudarte hoy?
            </Typography>
          </Box>
        )}

        {/* Sección Principal */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Avatar Fijo */}
          <Box
            className={`avatar-waves ${assistantStatus}`}
            sx={{
              position: 'fixed', // Fijar posición
              top: { xs: '60px', sm: '20px' }, // Ajustar para el AppBar
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              width: isMobile ? '80px' : '150px', // Reducir tamaño
              height: isMobile ? '80px' : '150px',
              marginBottom: '20px',
            }}
          >
            {showVideo ? (
              <VideoPlayer
                videoUrl={videoUrl}
                isVideoLoading={isVideoLoading}
                videoError={false}
                placeholderImage={assistantImageUrl}
              />
            ) : (
              <img
                src={assistantImageUrl}
                alt="Asistente"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  objectFit: 'cover',
                  position: 'relative',
                  zIndex: 2,
                }}
              />
            )}
          </Box>

          {/* Historial de Conversación */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '800px',
              flexGrow: 1,
              overflowY: 'auto',
              paddingTop: isMobile ? '100px' : '180px', // Espacio para el avatar
              paddingBottom: '70px', // Espacio para el input
              margin: '0 auto',
            }}
          >
            {chatMessages.map((message, index) => (
              <Box
                key={index}
                className={message.sender === 'assistant' ? 'assistant-message' : ''}
                sx={{
                  display: 'flex',
                  justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                  marginBottom: '10px', // Reducir margen
                }}
              >
                <Box
                  sx={{
                    maxWidth: '75%', // Reducir ancho
                    bgcolor: message.sender === 'user' ? '#F1F3F4' : '#FFFFFF',
                    color: '#000000',
                    p: 1.5, // Reducir padding
                    borderRadius: '12px', // Reducir border radius
                    boxShadow: '0px 1px 3px rgba(0,0,0,0.1)', // Reducir sombra
                    fontSize: '0.875rem', // Reducir tamaño de fuente
                  }}
                >
                  {message.text}
                </Box>
              </Box>
            ))}
            {isTyping && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <Box
                  sx={{
                    maxWidth: '75%',
                    bgcolor: '#FFFFFF',
                    color: '#000000',
                    p: 1.5,
                    borderRadius: '12px',
                    boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
                    fontSize: '0.875rem',
                  }}
                >
                  <Typography variant="body2">Escribiendo...</Typography>
                </Box>
              </Box>
            )}
            <div ref={chatEndRef} />
          </Box>

          {/* Iniciadores de Conversación */}
          {initialPrompts.length > 0 && (
            <Box
              sx={{
                width: '100%',
                maxWidth: '800px',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '8px', // Reducir gap
                margin: '0 auto',
                marginBottom: '15px',
              }}
            >
              {initialPrompts.map((prompt, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleInitialPromptClick(prompt)}
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: '#555555',
                    borderRadius: '18px', // Reducir border radius
                    textTransform: 'none',
                    fontSize: '0.75rem', // Reducir tamaño de fuente
                    padding: '6px 12px', // Reducir padding
                    '&:hover': {
                      backgroundColor: '#E0E0E0',
                    },
                  }}
                >
                  {prompt}
                </Button>
              ))}
            </Box>
          )}

          {/* Botones de Me gusta, Favorito y Compartir en móvil */}
          {isMobile && (
            <Box
              sx={{
                width: '100%',
                maxWidth: '800px',
                display: 'flex',
                justifyContent: 'space-around',
                gap: '8px',
                margin: '0 auto',
                marginBottom: '10px',
              }}
            >
              {/* Botón "Me gusta" */}
              <Button
                variant="outlined"
                startIcon={<ThumbUpIcon />}
                onClick={handleLike}
                sx={{
                  flex: '1',
                  height: '35px',
                  backgroundColor: isLiked ? '#D0E8FF' : '#E5E7EB',
                  borderRadius: '18px',
                  color: '#333333',
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  boxShadow: '0px 1px 3px rgba(0,0,0,0.05)',
                  '&:hover': {
                    backgroundColor: isLiked ? '#B0D8FF' : '#d1d5db',
                  },
                }}
              >
                Me gusta
              </Button>

              {/* Botón "Guardar como Favorito" */}
              <Button
                variant="outlined"
                startIcon={<FavoriteIcon />}
                onClick={handleFavorite}
                sx={{
                  flex: '1',
                  height: '35px',
                  backgroundColor: isFavorited ? '#FFD1DC' : '#E5E7EB',
                  borderRadius: '18px',
                  color: '#333333',
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  boxShadow: '0px 1px 3px rgba(0,0,0,0.05)',
                  '&:hover': {
                    backgroundColor: isFavorited ? '#FFC1CC' : '#d1d5db',
                  },
                }}
              >
                Favorito
              </Button>

              {/* Botón "Compartir" */}
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={handleShare}
                sx={{
                  flex: '1',
                  height: '35px',
                  backgroundColor: '#E5E7EB',
                  borderRadius: '18px',
                  color: '#333333',
                  fontSize: '0.75rem',
                  textTransform: 'none',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  boxShadow: '0px 1px 3px rgba(0,0,0,0.05)',
                  '&:hover': {
                    backgroundColor: '#d1d5db',
                  },
                }}
              >
                Compartir
              </Button>
            </Box>
          )}

          {/* Caja de Entrada de Texto Fija */}
          <Box
            sx={{
              position: 'fixed',
              bottom: isMobile ? '60px' : '20px', // Ajustar según si hay botones en móvil
              left: '50%',
              transform: 'translateX(-50%)',
              width: '90%',
              maxWidth: '800px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              padding: '5px',
              borderRadius: '20px',
              boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Escribe tu mensaje..."
              value={messageInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isMessageInputDisabled}
              sx={{
                bgcolor: '#FFFFFF',
                borderRadius: '20px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  paddingLeft: '10px',
                },
                fontSize: '0.75rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleVoiceInput} disabled={isMessageInputDisabled} size="small">
                      <MicIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={sendMessage} disabled={isMessageInputDisabled} size="small">
                      <SendIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Barra Lateral Derecha Permanente para Desktop */}
      {!isMobile && (
        <RightSidebar
          assistantName={assistantName}
          assistantImageUrl={assistantImageUrl}
          assistantDescription={assistantDescription}
          isMobile={false}
          assistantId={assistantId}
        />
      )}
    </Box>
  );
};

export default Assistant;

